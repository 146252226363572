import React, { Component } from "react"
import { connect } from "react-redux"
import { navigateTo } from "../../helpers/navigationHelpers"
import Alert from "../../components/shared/Alert"
import NoSessionLayout from "../../layouts/NoSessionLayout"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import SignUpForm from "../../components/sessions/SignUpForm"
import { FLEET } from "../../constants/application"
import { FMC } from "../../constants/roles"
import { withTranslation } from "react-i18next"
import { CANDA_COUNTRY_CODE, EN_LOCALE, FR_LOCALE, SIGN_IN, SIGN_UP } from "../../constants/users"
import { Checkbox as SematicCheckbox } from "semantic-ui-react"
import { setLanguage } from "../../helpers/translationHelpers"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import MultiFactorAuthentication from "../../components/shared/MultiFactorAuthentication"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import { setupSplitFlags, ON } from "../../components/config/SplitClient"
import * as sessionHelpers from "../../helpers/sessionHelpers"
let sub = getSubdomain()

class SessionNewSignup extends Component {
  constructor(props) {
    super(props)
    const locationStateData = this.props.location.state || {}
    this.state = {
      alertMessage: locationStateData.alertMessage || "",
      alertType: locationStateData.alertType || "default",
      isSubmitting: false,
      toggle: false,
      sendOTP: false,
      otpVerifyError: false,
      formData: {}
    } //state

    this.navigateTo = navigateTo.bind(this)
    this.navigateToSignIn = navigateTo.bind(this, "signIn")
    this.navigatePrivacyPolicy = navigateTo.bind(this, "privacyPolicy")
    this.onFormSubmit = this.onFormSubmit.bind(this)

    const path = locationStateData.path

    this.state.redirectPath = path
  } //constructor

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: sub })
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    const message = params.get("error_message")

    if (this.props.userSelectedLanguage === FR_LOCALE) {
      this.setState({ toggle: true })
    }
    if (message) this.setState({ alertMessage: message, alertType: "error" })
  } //componentDidMount

  onFormSubmit(formData, otp = null) {
    let split_flag =
      this.state.isMultifactorAuthenticationFlag && this.state.isMultifactorAuthenticationFlag == ON
        ? true
        : false
    this.setState({ isSubmitting: true, otpVerifyError: true })
    if (
      this.state.isMultifactorAuthenticationFlag &&
      this.state.isMultifactorAuthenticationFlag == ON
    ) {
      this.props.dispatch({
        type: "SIGN_IN_SAGA",
        payload: { formData: formData, t: this.props.t, subdomain: getSubdomain(), otp: otp },
        callback: this.afterFormSubmit.bind(this, SIGN_IN)
      })
    } else {
      this.props.dispatch({
        type: "SIGN_UP_SAGA",
        payload: {
          formData: formData,
          t: this.props.t,
          subdomain: getSubdomain(),
          toggle: this.state.toggle,
          is_split_flag: split_flag
        },
        callback: this.afterFormSubmit.bind(this, SIGN_UP)
      })
    }
  } //onFormSubmit

  afterFormSubmit(from, status, data) {
    this.setState({ isSubmitting: false })

    if (status === "success") {
      if (from == SIGN_IN) {
        this.props.dispatch({
          type: "CURRENT_USER_LOAD_SAGA",
          callback: this.onFormSubmitSuccess.bind(this)
        })
      } else {
        this.onFormSubmitSuccess(status, data)
      }
    } else {
      this.onFormSubmitFailure(data)
    }
  } //afterFormSubmit

  onSendOTP = (formData) => {
    this.setState({ sendOTP: true, formData: formData, toggle: this.state.toggle })
  }

  setError = (data, status, resend = false) => {
    if (!resend) {
      if (data && status == "failure") {
        this.setState({ sendOTP: false })
        this.setState({
          alertMessage: data.alertMessage || "",
          alertType: data.alertType
        })
      } else if (status == "success") {
        this.setState({ alertMessage: "OTP sent successfully", alertType: "success" })
      } else {
        this.setState({ alertMessage: "" })
      }
    } else if (resend && status == "success") {
      this.setState({ alertMessage: "OTP resent successfully", alertType: "success" })
    }
  }

  onFormSubmitSuccess(status, data) {
    if (this.state.redirectPath) this.props.history.push({ pathname: this.state.redirectPath })
    else if (data.roles.includes(FMC)) {
      if (this.props.isFleetMode)
        this.props.dispatch({
          type: "FMC_FLIP_SAGA",
          callback: null
        })
      this.navigateTo("fmcDashboard")
    } else {
      if (!this.props.isFleetMode)
        this.props.dispatch({
          type: "FMC_FLIP_SAGA",
          callback: null
        })
      this.navigateTo("dashboard")
    }
  }

  onFormSubmitFailure(data) {
    this.setState({
      alertMessage: data.alertMessage || "",
      alertType: data.alertType
    })
  }

  toggleButton = () => {
    this.setState((prevState) => ({ toggle: !prevState.toggle }))
    const preferences = this.state.toggle
      ? { language: EN_LOCALE, country: CANDA_COUNTRY_CODE }
      : { language: FR_LOCALE, country: CANDA_COUNTRY_CODE }
    setLanguage(preferences["language"], preferences["country"])
    this.props.dispatch({ type: "SET_USER_LANGUAGE", payload: { language: preferences.language } })
  }

  render() {
    const {
      alertMessage,
      alertType,
      isSubmitting,
      toggle,
      sendOTP,
      formData,
      otpVerifyError
    } = this.state
    const { dispatch, userSelectedLanguage, t } = this.props
    const isDomainShellCanada = isFleetMaintenanceHubCanada()
    return (
      <NoSessionLayout page="signupPage">
        <div
          className={"no-session-content"}
          style={{ minWidth: "49.4%", justifyContent: "start", paddingTop: "35px" }}
        >
          <Alert
            message={
              alertMessage["alertLabel"] != undefined
                ? t(`common:${alertMessage["alertLabel"]}`, {
                    email: alertMessage["data"["email"]]
                  })
                : t(`common:${alertMessage}`)
            }
            type={alertType}
            visible={alertMessage !== ""}
          />
          {isDomainShellCanada && (
            <div className={"sign-in-submit"} style={{ padding: "2px" }}>
              EN &nbsp;
              <SematicCheckbox toggle checked={toggle} onClick={this.toggleButton} />
              &nbsp; FR
            </div>
          )}
          <LoadingThrobber visible={isSubmitting} />
          {!isSubmitting && (
            <>
              {this.state.isMultifactorAuthenticationFlag &&
              this.state.isMultifactorAuthenticationFlag === ON &&
              sendOTP ? (
                <>
                  {" "}
                  <MultiFactorAuthentication
                    sendOTP={sendOTP}
                    otpVerifyError={otpVerifyError}
                    formData={formData}
                    onFormSubmit={this.onFormSubmit}
                    setError={this.setError}
                    signUp={true}
                  />{" "}
                </>
              ) : (
                <>
                  <SignUpForm
                    isSubmitting={isSubmitting}
                    onSubmit={
                      this.state.isMultifactorAuthenticationFlag &&
                      this.state.isMultifactorAuthenticationFlag === ON
                        ? this.onSendOTP
                        : this.onFormSubmit
                    }
                    navigateToSignIn={this.navigateToSignIn}
                    navigatePrivacyPolicy={this.navigatePrivacyPolicy}
                    userSelectedLanguage={userSelectedLanguage}
                    dispatch={dispatch}
                  />
                </>
              )}
            </>
          )}
        </div>
      </NoSessionLayout>
    ) //return
  } //render
} //class SessionNewSignup

const mapStateToProps = (state) => ({
  isFleetMode: state.application.mode === FLEET || state.application.mode === undefined,
  userSelectedLanguage: state.users ? state.users.userSelectedLanguage : "",
  currentUser: state.users.currentUser
})

export default connect(mapStateToProps)(withTranslation("common")(SessionNewSignup))

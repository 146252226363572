import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { trackEvent } from "../../utilities/segmentAnalytics"
import { getServiceNameForMap } from "../../helpers/serviceHelpers"
import {
  getBookedAppointmentDateTime,
  getBookingAppointmentTime
} from "../../helpers/segmentHelpers"
import moment from "moment"
import { Date_Format } from "../../constants/years"
import { AUTHENTICATION_TOKEN, MONTH, YEARS } from "../../constants/application"
import { FAILURE, SUCCESS } from "../../constants/notification"

const SERVICE_ORDERS_AGGREGATE_URL = "/orders/aggregate"
const ORDERS_URL = "/orders"
const FAVORITE_SHOPS_URL = "/shops/favorites"
const VEHICLES_URL = "/vehicles"
const SERVICES_URL = "/services"

// NOTE: Part 2: Submits the initial service request data along with a selected schedule date/time to create the service request on the backend.
export function* createServiceRequest(action) {
  try {
    yield put({
      type: "SERVICE_REQUEST_CREATE_REQUESTED"
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    const request = {
      url: ORDERS_URL,
      body: {
        ...action.payload.formData
      }
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "SERVICE_REQUEST_CREATE_SUCCEEDED"
    })

    let result = response.body

    let eventData = {}
    if (result) {
      eventData.appointmentDate = getBookedAppointmentDateTime(result.appointment_datetime)
      eventData.appointmentTime = getBookingAppointmentTime(result.appointment_datetime)
      if (result.shop) {
        eventData.shopName = result.shop.name
        eventData.shopAddress = result.shop.address
      }
      if (result.order_services && result.order_services.length > 0) {
        eventData.servicesSelected = result.order_services.map(getServiceNameForMap)
      }
      if (result.vehicle) {
        eventData.vehicleYear = result.vehicle.year
        eventData.vehicleMake = result.vehicle.make
        eventData.vehicleModel = result.vehicle.model
        eventData.vin = result.vehicle.vin
      }
    }
    trackEvent("appointment-requested", eventData)
    const afterRequestData = {
      alert: action.payload.t("serviceRequestCreatedLabel"),
      alertType: SUCCESS
    }

    yield call(action.callback, SUCCESS, afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "SERVICE_REQUEST_CREATE_FAILED"
    })

    const afterRequestData = {
      error: apiErrorHelpers.toString(errors)
    }

    yield call(action.callback, FAILURE, afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // createServiceRequest()

export function* loadServiceRequest(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    ////////////////////////
    //  LOAD SHOPS DATA   //
    ////////////////////////
    // NOTE: Shop data is only loaded if empty. This is so that "filtered" shop data is not overridden by "favorites" shop data every time.
    const shops = action.payload.shops || []

    if (shops.length === 0) {
      yield put({ type: "SHOPS_LOAD_REQUESTED" })

      const shopsRequest = {
        url: FAVORITE_SHOPS_URL,
        body: {}
      }

      const shopsResponse = yield call(api.utility.get, api.path(shopsRequest.url), {
        body: shopsRequest.body
      })

      apiErrorHelpers.catchErrors(shopsResponse.body)

      yield put({
        type: "SHOPS_LOAD_SUCCEEDED",
        payload: {
          shops: shopsResponse.body
        }
      })
    }
    ////////////////////////

    ////////////////////////
    // LOAD VEHICLES DATA //
    ////////////////////////

    const vehicles = action.payload.vehicles || []
    if (!action.payload.vehicle || vehicles.length === 0) {
      yield put({ type: "VEHICLES_LOAD_REQUESTED" })

      const vehiclesRequest = {
        url: VEHICLES_URL,
        body: {}
      }

      const vehiclesResponse = yield call(api.utility.get, api.path(vehiclesRequest.url), {
        body: vehiclesRequest.body
      })

      apiErrorHelpers.catchErrors(vehiclesResponse.body)

      yield put({
        type: "VEHICLES_LOAD_SUCCEEDED",
        payload: {
          vehicles: vehiclesResponse.body
        }
      })

      yield put({
        type: "SET_VEHICLES_DATA_TYPE",
        payload: { dataType: "standard" }
      })
    }

    ////////////////////////

    ////////////////////////
    // LOAD SERVICES DATA //
    ////////////////////////
    yield getAllServices()
    ////////////////////

    ////////////////////
    // LOAD CURRENT USER DATA //
    ////////////////////

    yield put({ type: "CURRENT_USER_LOAD_PAYMENT_METHODS_SAGA" })

    ////////////////////

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadServiceRequest

export function* getAllServices() {
  yield put({ type: "SERVICES_LOAD_REQUESTED" })

  const servicesRequest = {
    url: SERVICES_URL,
    body: { basic: true }
  }

  const servicesResponse = yield call(api.utility.get, api.path(servicesRequest.url), {
    body: servicesRequest.body
  })

  apiErrorHelpers.catchErrors(servicesResponse.body)

  yield put({
    type: "SERVICES_LOAD_SUCCEEDED",
    payload: {
      services: servicesResponse.body
    }
  })
}

export function* getAvailableTimes(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })
    const { services, shopId, vehicleId, date, callback } = action.payload

    const serviceDefinitionIds = services.map((service) => {
      return service.service_definition_id || service.id
    })
    let body = {
      vehicle_id: vehicleId,
      service_definition_ids: serviceDefinitionIds,
      date: date,
      days: 1
    }

    const response = yield call(api.utility.get, api.path(`/shops/${shopId}/schedule`), {
      body: body
    })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "AVAILABLE_TIMES_LOAD_SUCCEEDED",
      payload: { availableTimesData: response.body }
    })

    yield call(callback)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield call(action.payload.callback, apiErrorHelpers.toString(errors))

    apiErrorHelpers.consoleLog(errors)
  }
} // getAvailableTimes()

export function* getServicePrices(action) {
  try {
    yield put({ type: "SET_SERVICE_PRICES_LOADING" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    const servicesRequest = {
      url: SERVICES_URL,
      body: {
        ...action.payload,
        full_prices: true
      }
    }

    const servicesResponse = yield call(api.utility.get, api.path(servicesRequest.url), {
      body: servicesRequest.body
    })

    apiErrorHelpers.catchErrors(servicesResponse.body)

    yield put({
      type: "SERVICE_PRICES_LOAD_SUCCEEDED",
      payload: {
        servicePrices: servicesResponse.body
      }
    })
    yield put({ type: "UNSET_SERVICE_PRICES_LOADING" })
  } catch (errors) {
    // TODO: setup error handling on backend route and then here
    yield put({ type: "UNSET_SERVICE_PRICES_LOADING" })
  }
}

export function* getServiceOrdersAggregate(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "SERVICE_COSTS_LOAD_REQUESTED" })

    const serviceCostsRequest = {
      url: SERVICE_ORDERS_AGGREGATE_URL,
      body: {
        start_date: moment().subtract(YEARS, 1).format(Date_Format.YYYY_MM_01),
        end_date: moment().endOf(MONTH).format(Date_Format.YYYY_MM_DD),
        grouping: MONTH
      }
    }

    const serviceCostsResponse = yield call(api.utility.get, api.path(serviceCostsRequest.url), {
      body: serviceCostsRequest.body
    })

    apiErrorHelpers.catchErrors(serviceCostsResponse.body)

    yield put({
      type: "SERVICE_COSTS_LOAD_SUCCEEDED",
      payload: {
        serviceCosts: serviceCostsResponse.body
      }
    })

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // TODO: setup error handling on backend route and then here
    yield put({ type: "UNSET_SERVICE_PRICES_LOADING" })
  }
}

export default function* serviceRequestSaga() {
  yield takeEvery("SERVICE_REQUEST_CREATE_SAGA", createServiceRequest)
  yield takeEvery("SERVICE_REQUEST_LOAD_SAGA", loadServiceRequest)
  yield takeEvery("SERVICE_REQUEST_GET_AVAILABLE_TIMES_SAGA", getAvailableTimes)
  yield takeEvery("SERVICE_REQUEST_GET_PRICES_SAGA", getServicePrices)
  yield takeEvery("SERVICE_REQUEST_GET_ALL", getAllServices)
  yield takeEvery("SERVICE_REQUEST_ORDERS_AGGREGATE", getServiceOrdersAggregate)
}

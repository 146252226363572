import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Header, Grid, Container, Divider, Image } from "semantic-ui-react"
import VehicleModal from "./VehicleModal"
import { StateNamesAndAbbreviations } from "../../constants/states"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import { withTranslation } from "react-i18next"
import {
  isFleetcor,
  isFleetMaintenanceHub,
  isFleetMaintenanceHubCanada
} from "../../helpers/affiliationHelpers"
import { isEmpty } from "lodash"
import DocumentUploadModal from "./DocumentUploadModal"
import userIcon from "../../assets/images/driver_license_uploded.svg"
import documentIcon from "../../assets/images/custom_document.svg"
import { DRIVER } from "../../constants/roles"
import moment from "moment"
import { Date_Format } from "../../constants/years"
import { DOCUMENT_TYPE } from "../../constants/application"
import GeotabInfo from "./GeotabInfo"
import { ON } from "../config/SplitClient"
import { OIL_CHANGE } from "../../constants/services"
import { EDIT } from "../../constants/application"

class VehicleInfo extends Component {
  static propTypes = {
    vehicle: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      openDocumentModal: false,
      docType: ""
    }
  }

  handleDocumentView = (docType) => {
    this.setState({
      openDocumentModal: true,
      docType
    })
    this.props.dispatch({ type: "CLEAR_DOCUMENT_UPLOAD_MESSAGE" })
  }

  handleDocumentUpload = (docType) => {
    this.setState({
      openDocumentModal: true,
      docType
    })
    this.props.dispatch({ type: "CLEAR_DOCUMENT_UPLOAD_MESSAGE" })
  }

  updateDriverLienseState = (value) => {
    this.setState({ openDocumentModal: value })
  }

  renderViewOrUploadDocumentLink = (
    document,
    displayInline,
    icon,
    viewOnClick,
    uploadOnClick,
    docType
  ) => {
    const { userRoles = [], t } = this.props
    const isUserOnlyDriver = userRoles.length === 1 ? userRoles[0] === DRIVER : false
    return !isEmpty(document) ? (
      <>
        <Image src={icon} style={displayInline} onClick={() => viewOnClick(docType)} />
        &nbsp;&nbsp;
        <span className="link" onClick={() => viewOnClick(docType)}>
          {t("viewLabel")}
        </span>
      </>
    ) : isUserOnlyDriver && docType !== DOCUMENT_TYPE.DRIVER_LICENSE ? (
      <>
        <Image src={icon} style={{ ...displayInline, ...{ cursor: "not-allowed" } }} />
        &nbsp;&nbsp;
        <span style={{ cursor: "not-allowed" }}>{t("uploadLabel")}</span>
      </>
    ) : (
      <>
        <Image src={icon} style={displayInline} onClick={() => uploadOnClick(docType)} />
        &nbsp;&nbsp;
        <span className="link" onClick={() => uploadOnClick(docType)}>
          {t("uploadLabel")}
        </span>
      </>
    )
  }

  renderDocumentModal = () => {
    const { openDocumentModal, docType } = this.state
    const {
      vehicle,
      driverLicence,
      states,
      dispatch,
      userFirstName,
      vehicleInsurance,
      registration,
      customDocuments,
      userRoles
    } = this.props
    return (
      <DocumentUploadModal
        open={openDocumentModal}
        showOrHideModal={this.updateDriverLienseState}
        states={states}
        dispatch={dispatch}
        vehicleId={vehicle.id}
        driverLicence={driverLicence}
        vehicleInsurance={vehicleInsurance}
        registration={registration}
        docType={docType}
        userFirstName={userFirstName}
        customDocuments={customDocuments}
        userRoles={userRoles}
      />
    )
  }

  render() {
    const {
      vehicle,
      loadVehicle,
      language,
      t,
      driverLicence,
      vehicleInsurance,
      registration,
      customDocuments,
      isGloveboxFeatureFlag,
      dispatch,
      engineOilData,
      tirePressureAndTempData,
      batteryLifeData,
      faultCodesData,
      isLoading,
      isGeotabSectionFlag,
      serviceSchedulesByVehicle,
      faultCodeService
    } = this.props
    let state = StateNamesAndAbbreviations.find(
      (s) => s.abbreviation === vehicle.license_plate_state
    )
    const serviceSchedule = (serviceSchedulesByVehicle[vehicle.id] || [])
      .filter((upcomingService) => upcomingService.recommended)
      .find((upcomingService) => upcomingService.name === OIL_CHANGE)
    const isFleetShellCanada = isFleetMaintenanceHubCanada()
    const displayFlex = { display: "flex" }
    const displayInline = { display: "inline", cursor: "pointer" }
    const { openDocumentModal } = this.state

    return (
      <div>
        <Header size="medium">
          {t("vehicleInformationLabel")}&nbsp;&nbsp;&nbsp;
          <VehicleModal completionCallback={loadVehicle} vehicle={vehicle}>
            <span className="link">{t("common:editLabel")}</span>
          </VehicleModal>
        </Header>
        <Grid columns={4} padded="horizontally">
          <Grid.Column>
            <p>
              {t("vinLabel")}: {vehicle.vin || "-"}
            </p>
            <p>
              {isFleetShellCanada
                ? `${t("dashboard:mileageLabel")}`
                : `${t("dashboard:milesLabel")}`}
              : {vehicleHelpers.formattedMiles(vehicle)}
            </p>
            <p>
              {t("dashboard:plateLabel")} #: {vehicleHelpers.formattedLicensePlateNumber(vehicle)}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p>
              {isFleetShellCanada
                ? `CPK: $${vehicleHelpers.formattedCostPerMile(vehicle)} CAD`
                : `CPM: $${vehicleHelpers.formattedCostPerMile(vehicle)}`}
            </p>
            <p>
              {isFleetShellCanada ? t("provinceLabel") : t("stateLabel")}:{" "}
              {(state && state.name) || vehicle.license_plate_state || "-"}
            </p>
            <p>
              {t("lastMaintenanceLabel")}:{" "}
              {vehicleHelpers.formattedLastMaintenanceDate(vehicle, language)}
            </p>
          </Grid.Column>
          <Grid.Column width={3}>
            {!isFleetShellCanada ? (
              <p>
                {t("expirationDateLabel")}
                {": "}
                {vehicleHelpers.formattedLicensePlateExpiration(vehicle, language)}
              </p>
            ) : null}
            <p>
              {t("departmentNumber")}: {vehicle.department_number}
            </p>
          </Grid.Column>
          <Grid.Column width={4}>
            <div style={{ float: "right", top: 0, position: "relative" }}>
              <Image size="small" src={vehicle.dashboard_url || "/images/no_car_large.svg"} />
            </div>
          </Grid.Column>
        </Grid>
        {isGeotabSectionFlag &&
        isGeotabSectionFlag === ON &&
        vehicle &&
        vehicle.geotab_device_id !== null ? (
          <>
            <Divider />
            <GeotabInfo
              engineOilData={engineOilData}
              tirePressureAndTempData={tirePressureAndTempData}
              batteryLifeData={batteryLifeData}
              faultCodesData={faultCodesData}
              serviceSchedule={serviceSchedule}
              vehicle={vehicle}
              faultCodeService={faultCodeService}
            />
          </>
        ) : (
          <></>
        )}
        {isGloveboxFeatureFlag && isGloveboxFeatureFlag === ON && (
          <>
            <Divider />
            <Header>{t("gloveboxHeaderLbl")}</Header>
            <Grid compact columns="equal" padded="horizontally">
              <Grid.Column>
                <p style={displayFlex}>
                  {t("drivingLicenseLabel")}:&nbsp;
                  {this.renderViewOrUploadDocumentLink(
                    driverLicence,
                    displayInline,
                    userIcon,
                    this.handleDocumentView,
                    this.handleDocumentUpload,
                    DOCUMENT_TYPE.DRIVER_LICENSE
                  )}
                </p>
                <p>
                  {t("expirationDateLabel")}
                  {": "}
                  {!isEmpty(driverLicence.expiration_date) ? (
                    <b>
                      {moment(driverLicence.expiration_date).format(
                        isFleetShellCanada ? Date_Format.YYYY_MM_DD : Date_Format.MM_DD_YYYY
                      )}
                    </b>
                  ) : (
                    <b>{t("notAvailableLabel")}</b>
                  )}
                </p>
              </Grid.Column>
              <Grid.Column>
                <p style={displayFlex}>
                  {t("vehicleInsuranceLabel")}:&nbsp;
                  {this.renderViewOrUploadDocumentLink(
                    vehicleInsurance,
                    displayInline,
                    documentIcon,
                    this.handleDocumentView,
                    this.handleDocumentUpload,
                    DOCUMENT_TYPE.VEHICLE_INSURANCE
                  )}
                </p>
                <p>
                  {t("expirationDateLabel")}
                  {": "}
                  {!isEmpty(vehicleInsurance.expiration_date) ? (
                    <b>
                      {moment(vehicleInsurance.expiration_date).format(
                        isFleetShellCanada ? Date_Format.YYYY_MM_DD : Date_Format.MM_DD_YYYY
                      )}
                    </b>
                  ) : (
                    <b>{t("notAvailableLabel")}</b>
                  )}
                </p>
              </Grid.Column>
              <Grid.Column>
                <p style={displayFlex}>
                  {t("registrationLabel")}:&nbsp;
                  {this.renderViewOrUploadDocumentLink(
                    registration,
                    displayInline,
                    documentIcon,
                    this.handleDocumentView,
                    this.handleDocumentUpload,
                    DOCUMENT_TYPE.REGISTRATION
                  )}
                </p>
                <p>
                  {t("expirationDateLabel")}
                  {": "}
                  {!isEmpty(registration.expiration_date) ? (
                    <b>
                      {moment(registration.expiration_date).format(
                        isFleetShellCanada ? Date_Format.YYYY_MM_DD : Date_Format.MM_DD_YYYY
                      )}
                    </b>
                  ) : (
                    <b>{t("notAvailableLabel")}</b>
                  )}
                </p>
              </Grid.Column>
              <Grid.Column>
                <p>
                  {t("extraDocumentsLable")}:&nbsp;
                  {this.renderViewOrUploadDocumentLink(
                    customDocuments,
                    displayInline,
                    documentIcon,
                    this.handleDocumentView,
                    this.handleDocumentUpload,
                    DOCUMENT_TYPE.OTHER
                  )}
                </p>
                <p>
                  {t("totalDocumentsLabel")}:{" "}
                  {!isEmpty(customDocuments) ? <b>{customDocuments.length}</b> : <b>0</b>}
                </p>
              </Grid.Column>
            </Grid>
            {openDocumentModal ? this.renderDocumentModal() : null}
          </>
        )}
      </div>
    )
  }
} // class VehicleInfo

const mapStateToProps = (state) => ({
  language: state.users.currentUser && state.users.currentUser.language,
  userFirstName: state.users.currentUser && state.users.currentUser.firstName,
  userRoles:
    (state.application.userSessionData && state.application.userSessionData.roles) ||
    (state.users.currentUser && state.users.currentUser.roles),
  states: state.users.states,
  affiliation: state.affiliations.affiliation
})

export default connect(mapStateToProps)(withTranslation("vehicle")(VehicleInfo))

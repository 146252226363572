import { browserName } from "react-device-detect"
import moment from "moment"
import store from "../actions/configureStore"

export const getDateFormat = () => "MM-DD-YYYY"

export const getEventNameByKey = (key) => {
  const state = store.getState()

  if (state && state.segment && state.segment.eventNames && state.segment.eventNames[key]) {
    return state.segment.eventNames[key]
  }
  return null
}
export const getDeviceProp = () => ({ device: `Web - ${browserName.toLowerCase()}` })

export const getBookingAppointmentDay = (appointmentDateTime) => {
  if (appointmentDateTime)
    moment(appointmentDateTime, "YYYY-MM-DD HH:mm:ss").format("dddd").toISOString()
}

export const getAppointmentDateTime = (appointmentDateTime, timeString) => {
  if (appointmentDateTime) {
    const date = moment(appointmentDateTime).format("YYYY-MM-DD") + " " + timeString
    return moment(date).format("YYYY-MM-DD HH:mm:ss")
  }
}
export const getBookingAppointmentDateTime = (appointmentDateTime) => {
  if (appointmentDateTime) {
    return appointmentDateTime.replace("T", " ") + ":00"
  }
}
export const getBookedAppointmentDateTime = (appointmentDateTime) => {
  if (appointmentDateTime) {
    return appointmentDateTime.slice(0, 19).replace("T", " ")
  }
}
export const getBookingAppointmentTime = (appointmentDateTime) => {
  if (appointmentDateTime) {
    const formattedDateTime = moment(appointmentDateTime, "YYYY-MM-DD HH:mm:ss")
    const appointmentTime = formattedDateTime.format("HH:mm")
    return appointmentTime
  }
}

import React from "react"

import { Checkbox, Divider, Icon, Image } from "semantic-ui-react"
import RecommendedServiceModal from "../RecommendedServiceModal"
import { isFleetAdvise } from "../../../../helpers/affiliationHelpers"

const Services = (props) => {
  const {
    service,
    selectedServices,
    completed,
    showMarkCompletedCheckBox = true,
    isLast = false,
    addorRemoveServices,
    onMarkasCompletedCheckboxClick,
    markAllCompleted,
    serviceToBeMarkedAsCompleted
  } = props
  const [open, setOpen] = React.useState(false)
  let isChecked = false
  const [show, setShow] = React.useState(true)
  React.useEffect(() => {
    if (!isChecked) {
      setShow(true)
    }
  }, [isChecked])
  if (markAllCompleted) {
    const serviceIndex = serviceToBeMarkedAsCompleted.findIndex(
      (s) => s.service_id === service.service_id
    )
    isChecked = serviceIndex == -1 ? false : true
  } else {
    const serviceIndex = selectedServices.findIndex((s) => s.service_id === service.service_id)
    isChecked = serviceIndex == -1 ? false : true
  }
  const openModal = () => {
    setOpen(true)
  }
  const closeModal = (data) => {
    setOpen(false)
  }

  const onSave = (data) => {
    if (data) {
      addorRemoveServices(service, data)
    } else {
      addorRemoveServices(service)
      isChecked = false
    }
    closeModal()
  }
  return (
    <>
      <div className="manufacturer-title">
        {markAllCompleted && (
          <div style={{ flexDirection: "row" }}>
            <Icon.Group size="large" className="back-icon">
              <Icon
                name={
                  completed
                    ? "check circle"
                    : isChecked
                    ? isFleetAdvise()
                      ? "check circle checkbox-color"
                      : "check circle"
                    : "circle outline"
                }
                disabled={completed}
                onClick={() => !completed && onMarkasCompletedCheckboxClick(service)}
              />
            </Icon.Group>
            <span>{service.service_name}</span>
          </div>
        )}
        {!markAllCompleted && <span>{service.service_name}</span>}
        {completed && (
          <Image src="../../../../images/completedIcon.png" className="completed-image" />
        )}

        {markAllCompleted == false && !completed && (
          <Checkbox
            checked={isChecked}
            onChange={(e) => {
              // e.preventDefault()
              if (service.positions && service.positions.length > 1) {
                openModal()
              }

              if (!service.positions || service.positions.length < 1) {
                addorRemoveServices(service)
              }
            }}
            onClick={() => {
              if (isChecked && service.positions && service.positions.length > 1) {
                addorRemoveServices(service)
                isChecked = true
                setShow(false)
              }
              if (!isChecked && service.positions && service.positions.length > 1) {
                // addorRemoveServices(service)
                isChecked = true
                setShow(true)
              }
            }}
          />
        )}
        {show && service.positions && service.positions.length > 1 && (
          <RecommendedServiceModal
            serviceOptions={service.positions}
            isOpenModel={open}
            serviceName={service.service_name}
            closeModal={closeModal}
            openModal={openModal}
            onSave={onSave}
          />
        )}
      </div>
      <Divider />
    </>
  )
}

export default Services

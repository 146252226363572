import React from "react"
import { Input, Grid, Button, Checkbox, Image } from "semantic-ui-react"
import VehicleModal from "./VehicleModal"
import BulkUploadModal from "../../components/shared/BulkUploadModal"
import { useTranslation } from "react-i18next"
import {
  isFleetAdvise,
  isFleetMaintenanceHubCanada,
  isFleetcor
} from "../../helpers/affiliationHelpers"
import ResetIcon from "../../assets/images/ResetIcon.svg"
import UploadIcon from "../../assets/images/UploadIcon.png"
import DownloadIcon from "../../assets/images/DownloadIcon.png"

export default function VehicleSearch({
  debouncedOnFilterChange,
  resetFiltering,
  loadVehicles,
  sizeToFit,
  debouncedOnSizeToFitChange,
  dispatch,
  language,
  currentUser,
  vehicles = []
}) {
  const { t } = useTranslation("vehicleIndex")

  const handleDownloadClick = async () => {
    await dispatch({
      type: "VEHICLES_LOAD_SAGA",
      payload: {
        download: true,
        language: language,
        isDomainShellCanada: isFleetMaintenanceHubCanada()
      }
    })
  }

  return (
    <Grid columns="equal" className={"ag-theme-search"} stackable>
      <Grid.Column textAlign="left">
        <Grid stackable>
          <Grid.Column width={3}>
            <Input
              className="search"
              onChange={debouncedOnFilterChange}
              icon="search"
              placeholder={t("searchVechilesLabel")}
            />
          </Grid.Column>
          <Grid.Column width={3} style={{ paddingLeft: "65px" }}>
            <Checkbox
              label={t("sizeToFitLabel")}
              onChange={debouncedOnSizeToFitChange}
              checked={sizeToFit}
            />
          </Grid.Column>
          <Grid.Column width={10} textAlign="right">
            {
              <Button
                className={`white-button ${isFleetAdvise() ? "btn-margin-right" : ""}`}
                onClick={resetFiltering}
              >
                {isFleetAdvise() ? <Image src={ResetIcon} className="vehicle-icons" /> : null}
                {t("resetFiltersLabel")}
              </Button>
            }
            <Button.Group>
              {isFleetcor(currentUser) ? (
                ""
              ) : (
                <>
                  <VehicleModal completionCallback={loadVehicles}>
                    <Button>
                      {isFleetAdvise() ? (
                        <Image src={UploadIcon} className="vehicle-icons" />
                      ) : null}
                      {t("addVehicleLabel")}
                    </Button>
                  </VehicleModal>
                  <Button.Or text={t("orLabel")} />
                  <BulkUploadModal completionCallback={loadVehicles}>
                    <Button className={`last-button ${isFleetAdvise() ? "btn-margin-right" : ""}`}>
                      {isFleetAdvise() ? (
                        <Image src={UploadIcon} className="vehicle-icons" />
                      ) : null}
                      {t("bulkUploadLabel")}
                    </Button>
                  </BulkUploadModal>
                </>
              )}
            </Button.Group>
            &nbsp;
            <Button onClick={handleDownloadClick} disabled={vehicles.length === 0}>
              {isFleetAdvise() ? <Image src={DownloadIcon} className="vehicle-icons" /> : null}
              {t("services:downloadLabel")}
            </Button>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>
  )
}

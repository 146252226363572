import React, { Component } from "react"
import { connect } from "react-redux"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import { withTranslation } from "react-i18next"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"

const subdomain = getSubdomain()
export const PRIVACY_POLICIES = {
  shell: "https://caradvise.com/privacy-policy-fmh",
  fleetcor: "https://www.fuelman.com/privacy-policy/",
  fuelman: "https://www.fuelman.com/privacy-policy/",
  ntw: "https://www.ntw.com/privacy-policy",
  discounttire: "https://www.discounttire.com/customer-service/privacy-policy"
}
export const ADDENDUM = {
  fuelman: "https://fuelmanmaintenance.com/fuelman-addendum/"
}

function termsOfUseUrl() {
  if (subdomain.match(/fleetcor/) || subdomain.match(/fuelman/)) {
    return "https://caradvise.com/fuelmanterms/"
  } else if (subdomain.match(/ntw/)) {
    return "https://www.ntw.com/terms-of-use"
  } else if (subdomain.match(/discounttire/)) {
    return "https://www.discounttire.com/customer-service/terms-of-use"
  } else {
    return "http://caradvise.com/terms/"
  }
}

const FuelmanTermsAndAddendumLink = ({ t }) => {
  return (
    <div style={{ fontSize: "0.8em", position: "relative", top: "-3px" }}>
      {t("common:iHaveReadLabel")}
      <a
        style={{ textDecoration: "underline", color: "unset" }}
        href={termsOfUseUrl()}
        rel="noopener noreferrer"
        target="_blank"
      >
        {t("common:termsConditionsLabel")}
      </a>
      {t("common:andTheLabel")}
      <a
        style={{ textDecoration: "underline", color: "unset" }}
        href={ADDENDUM.fuelman}
        rel="noopener noreferrer"
        target="_blank"
      >
        {t("common:fuelManAddendumLabel")}
      </a>
      {t("common:confirmAcceptanceLabel")}
    </div>
  )
}

class PrivacyPolicyPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        {subdomain.match(/fuelman/) ? (
          <FuelmanTermsAndAddendumLink t={t} />
        ) : (
          <div
            style={{ fontSize: "0.8em", position: "relative", top: "-3px" }}
            className={isFleetAdvise() ? "privacy-policy-link-color" : ""}
          >
            {t("common:readAcceptTermLabel")}
            <a
              style={{ textDecoration: "underline", color: "unset" }}
              href={termsOfUseUrl()}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t("common:termsConditionsLabel")}
            </a>
          </div>
        )}
      </React.Fragment>
    )
  }
} // class PrivacyPolicyPage

export default connect()(withTranslation("common")(PrivacyPolicyPage))

import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { manualMaintenanceHistoriesWithVehicle } from "../../helpers/maintenanceHistoryHelpers"
import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import ServiceList from "../../components/services/ServiceList"
import moment from "moment"
import { PAST } from "../../constants/services"
import { Date_Format } from "../../constants/years"
import { ACTIVE, AWAITING_APPROVAL } from "../../constants/statuses"
import { FLEET, MONTH, DAY } from "../../constants/application"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"
import ServiceIndexShimmer from "./ServiceIndexShimmer"

class ServiceIndex extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    vehicles: PropTypes.array.isRequired,
    manualMaintenanceHistories: PropTypes.array.isRequired,
    shopOrders: PropTypes.array.isRequired,
    maintenanceIntervals: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    // NOTE: locationStateData is instantiated as a variable here rather than referencing this.props.location.state.someObj directly because the state key may not be present when navigating to this page from a <Link> that does not explicitly set it.
    const locationStateData = this.props.location.state || {}
    let pastStartDate, pastEndDate
    let startingDate = locationStateData.startingDate
      ? moment(locationStateData.startingDate, "YYYY-MM-DD HH:mm:ss Z").add(1, DAY) // Shitty hack to account for UTC being off-one-day for the continental US
      : moment()
    // sometimes shitty hack makes us go to day 2, subtract.
    if (startingDate.date() == 2) startingDate = startingDate.subtract(1, DAY)
    if (locationStateData && locationStateData.startingDate) {
      pastStartDate = startingDate.startOf(DAY)
      pastEndDate = moment(startingDate).add(1, MONTH).endOf(DAY)
    } else {
      pastStartDate = moment(startingDate).subtract(3, MONTH).endOf(DAY)
      pastEndDate = startingDate.startOf(DAY)
    }
    this.state = {
      alertMessage: locationStateData.alertMessage || "",
      alertType: locationStateData.alertType || "default",
      pastStartDate: pastStartDate,
      pastEndDate: pastEndDate,
      activeStartDate: startingDate.startOf(DAY),
      activeEndDate: moment(startingDate).add(3, MONTH).endOf(DAY),
      startingPage: locationStateData.startingPage,
      orderFilter: locationStateData.orderFilter
    }
  }

  handlePastOrderDateChange = async (date, name) => {
    const isActiveOrder = false
    await this.setState({
      startingPage: PAST,
      [name]: date
    })
    this.refreshActiveAndPastOrders({ skipUnsetLoading: false }, isActiveOrder)
  }

  handleActiveOrderDateChange = async (date, name) => {
    const isActiveOrder = true
    await this.setState({
      startingPage: ACTIVE,
      [name]: date
    })
    this.refreshActiveAndPastOrders({ skipUnsetLoading: false }, isActiveOrder)
  }

  getShopOrders = async (isActiveOrder, opts) => {
    const { activeStartDate, pastStartDate, activeEndDate, pastEndDate } = this.state
    const { dispatch } = this.props
    isActiveOrder
      ? this.loadActiveShopOrders(dispatch, activeStartDate, activeEndDate, opts)
      : this.loadPastShopOrders(dispatch, pastStartDate, pastEndDate, opts)
  }

  loadAllShopOrders = async (opts) => {
    const { activeStartDate, pastStartDate, activeEndDate, pastEndDate } = this.state
    const { dispatch } = this.props
    this.loadActiveShopOrders(dispatch, activeStartDate, activeEndDate, opts)
    this.loadPastShopOrders(dispatch, pastStartDate, pastEndDate, opts)
  }

  loadActiveShopOrders = (dispatch, activeStartDate, activeEndDate, opts) => {
    dispatch({
      type: "SHOP_ORDERS_LOAD_SAGA",
      payload: {
        options: {
          startDate: activeStartDate.format(Date_Format.YYYY_MM_DD),
          endDate: activeEndDate.format(Date_Format.YYYY_MM_DD),
          captureActive: true,
          withMaintenanceHistory: false
        },
        skipUnsetLoading: opts.skipUnsetLoading
      }
    })
  }

  loadPastShopOrders = (dispatch, pastStartDate, pastEndDate, opts) => {
    dispatch({
      type: "SHOP_ORDERS_LOAD_SAGA",
      payload: {
        options: {
          startDate: pastStartDate.format(Date_Format.YYYY_MM_DD),
          endDate: pastEndDate.format(Date_Format.YYYY_MM_DD),
          captureActive: false,
          withMaintenanceHistory: true
        },
        skipUnsetLoading: opts.skipUnsetLoading
      }
    })
  }

  refreshActiveAndPastOrders = (opts, isActiveOrder, orders = []) => {
    if (isActiveOrder && orders.length) {
      const appointment_dates = []
      orders.forEach((order) => {
        if (order.active_order && order.active_order.appointment_datetime) {
          appointment_dates.push(moment(order.active_order.appointment_datetime))
        }
      })
      if (appointment_dates.length) {
        const startDate = Math.min.apply(null, appointment_dates)
        const endDate = Math.max.apply(null, appointment_dates)
        this.setState(
          {
            activeStartDate: moment(startDate).subtract(1, DAY),
            activeEndDate: moment(endDate).add(1, DAY)
          },
          () => this.getShopOrders(isActiveOrder, opts)
        )
        return
      }
    }
    this.getShopOrders(isActiveOrder, opts)
  }

  loadActiveAndPastOrders = async (opts, orders = []) => {
    this.loadAllShopOrders(opts)
  }

  refreshVehicles = async () => {
    await this.props.dispatch({
      type: "VEHICLES_LOAD_SAGA",
      payload: {
        addMaintenance: true
      },
      callback: this.handleVehicleLoadSuccess
    })
  }

  handleVehicleLoadSuccess = (response) => {
    this.loadActiveAndPastOrders({ skipUnsetLoading: true }, response)
  }

  refreshMaintenanceIntervals = async () => {
    await this.props.dispatch({
      type: "MAINTENANCE_INTERVAL_SAGA"
    })
  }

  async componentDidMount() {
    this.loading = true
    const { render_active_order_tab, dispatch, fleetId } = this.props
    const { orderFilter } = this.state

    if (render_active_order_tab || orderFilter) {
      await dispatch({
        type: "FLEET_LOAD_SAGA",
        payload: {
          fleetId: fleetId,
          options: {
            includePaymentTypes: true
          }
        }
      })
      await dispatch({
        type: "FLEET_IMPERSONATION_SAGA",
        payload: { fleetId },
        callback: this.afterImpersonationRequest
      })
      if (!orderFilter) {
        this.setState({
          orderFilter: [AWAITING_APPROVAL]
        })
      }
    } else {
      this.refreshVehicles()
      this.refreshMaintenanceIntervals()
    }
    this.loading = false
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: "ACTIVE_SHOP_ORDERS_UNLOAD_DATA"
    })
    dispatch({
      type: "PAST_SHOP_ORDERS_UNLOAD_DATA"
    })
    dispatch({
      type: "VEHICLES_UNLOAD_DATA"
    })
  }

  afterImpersonationRequest = async (status, data) => {
    if (status === "success") {
      await this.props.dispatch({
        type: "FMC_FLIP_SAGA",
        payload: { mode: FLEET },
        callback: this.afterFlipSaga
      })
      this.setState({
        startingPage: ACTIVE
      })
    }
  }

  afterFlipSaga = () => {
    this.refreshVehicles()
    this.refreshMaintenanceIntervals()
  }

  renderContent() {
    const {
      isLoading,
      isLoadingError,
      shopOrders = [],
      vehicles,
      maintenanceIntervals,
      manualMaintenanceHistories,
      language,
      isVehicleLoading,
      activeShopOrders = [],
      vehicleReminders = []
    } = this.props

    const {
      activeStartDate,
      activeEndDate,
      pastEndDate,
      pastStartDate,
      startingPage,
      orderFilter
    } = this.state

    if (
      !isLoading &&
      !isLoadingError &&
      !isVehicleLoading &&
      !this.loading &&
      (shopOrders || activeShopOrders) &&
      vehicles
    ) {
      return (
        <ServiceList
          shopOrders={shopOrders.concat(manualMaintenanceHistories)}
          activeShopOrders={activeShopOrders.concat(manualMaintenanceHistories)}
          vehicles={vehicles}
          serviceSchedulesByVehicle={maintenanceIntervals.service_schedules_by_vehicle}
          history={this.props.history}
          pastStartDate={pastStartDate}
          pastEndDate={pastEndDate}
          activeStartDate={activeStartDate}
          activeEndDate={activeEndDate}
          pastOrderDateChange={this.handlePastOrderDateChange}
          activeOrderDateChange={this.handleActiveOrderDateChange}
          startingPage={startingPage}
          orderFilter={orderFilter}
          language={language}
          vehicleReminders={vehicleReminders}
        />
      )
    } else {
      return isFleetAdvise() ? <ServiceIndexShimmer /> : null
    }
  }

  render() {
    const { isLoading, isLoadingError, isVehicleLoading } = this.props

    const { alertMessage, alertType } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        {!isFleetAdvise() ? <LoadingThrobber visible={isLoading || isVehicleLoading} /> : null}

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class UserIndex

const mapStateToProps = (state) => {
  const vehicles = state.vehicles.vehicles
  const manualHistory = state.maintenanceHistories.manualMaintenanceHistories
  const language = state.users.currentUser ? state.users.currentUser.language : ""
  const { isVehicleLoading, isLoading, isLoadingError } = state.application
  const { snoozedReminderDates } = state.maintenanceReminderReducers

  return {
    isLoading,
    isLoadingError,
    shopOrders: state.shopOrders.shopOrders,
    activeShopOrders: state.shopOrders.activeShopOrders,
    vehicles: vehicles,
    maintenanceIntervals: state.maintenanceIntervals,
    manualMaintenanceHistories: manualMaintenanceHistoriesWithVehicle(manualHistory, vehicles),
    language,
    render_active_order_tab: state.services.render_active_order_tab,
    fleetId: state.services.fleetId || (state.fleets.fleet ? state.fleets.fleet.id : null),
    isVehicleLoading,
    vehicleReminders: snoozedReminderDates ? snoozedReminderDates.vehicle_reminders : []
  }
}

export default connect(mapStateToProps)(ServiceIndex)

import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { orderBy, isEmpty } from "lodash"
import { navigationPaths } from "../../constants/paths"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import MaintenanceHistory from "../../components/vehicles/MaintenanceHistory"
import MaintenancePolicies from "../../components/maintenance_policies/MaintenancePolicies"
import MaintenanceScheduleIndex from "../../components/vehicles/MaintenanceSchedules/MaintenanceScheduleIndex"
import MaintenanceSchedule from "../../components/vehicles/MaintenanceSchedule"
import VehicleInfo from "../../components/vehicles/VehicleInfo"
import { Grid, Menu, Segment, Image, Header, Divider } from "semantic-ui-react"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import ScheduleServiceButton from "../../components/shared/ScheduleServiceButton"
import { withTranslation } from "react-i18next"
import VehicleMaintenceSchedule from "../../components/vehicles/VehicleMaintenceSchedule"
import { isFleetAdvise, isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { CANDA_COUNTRY_CODE, US_COUNTRY_CODE } from "../../constants/users"
import { DOCUMENT_TYPE } from "../../constants/application"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import { ON, OFF, setupSplitFlags } from "../../components/config/SplitClient"
import { FAULT_CODE_INSPECTION } from "../../constants/services"

class VehicleShow extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    services: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    vehicle: PropTypes.object.isRequired,
    vehicles: PropTypes.array.isRequired
  }

  /* Components are functions it covers updates */
  state = {
    isDocumentClearInvoke: false,
    alertMessage: "",
    alertType: "default",
    vehicleId: this.props.match.params.id,
    activePage: "overview",
    isMaintenanceScheduleLoading: false,
    pages: [
      {
        name: "overview",
        label: "vechicleOverviewLabel",
        component: () => (
          <VehicleInfo
            loadVehicle={this.fetchVehicleData}
            vehicle={this.props.vehicle}
            driverLicence={this.props.driverLicence}
            vehicleInsurance={this.props.vehicleInsurance}
            registration={this.props.registration}
            customDocuments={this.props.customDocuments}
            isGloveboxFeatureFlag={this.state.isGloveboxFeatureFlag}
            engineOilData={this.props.engineOil}
            tirePressureAndTempData={this.props.tirePressureAndTemp}
            batteryLifeData={this.props.batteryLife}
            faultCodesData={this.props.faultData}
            isGeotabSectionFlag={this.state.isGeotabSectionFlag}
            serviceSchedulesByVehicle={this.props.maintenanceIntervals.service_schedules_by_vehicle}
            faultCodeService={this.props.faultCodeService}
          />
        )
      },
      {
        name: "maintenance_history",
        label: "maintenanceHistoryLabel",
        component: () => (
          <MaintenanceHistory
            afterDeleteMaintenanceHistory={this.afterDeleteMaintenanceHistory}
            {...this.props}
          />
        )
      },
      {
        name: "maintenance_schedule",
        label: "maintenanceScheduleLabel",
        component: () =>
          this.state.isMaintenanceScheduleFlag && this.state.isMaintenanceScheduleFlag == ON ? (
            <MaintenanceScheduleIndex />
          ) : (
            <VehicleMaintenceSchedule
              {...this.props}
              isMaintenanceScheduleLoading={this.state.isMaintenanceScheduleLoading}
            />
          )
      },
      {
        name: "policies",
        label: "policiesLabel",
        component: () => (
          <MaintenancePolicies
            allowActions={false}
            onDelete={this.onMaintenancePolicyDelete.bind(this)}
            onRedirect={this.navigateToPolicies.bind(this)}
            onSubmit={this.onMaintenancePolicySubmit.bind(this)}
            policies={this.props.vehicle.policies}
            services={this.props.services}
            users={this.props.users}
            vehicle={this.props.vehicle}
            vehicles={this.props.vehicles}
            language={this.props.language}
          />
        )
      }
    ]
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })

    if (this.state.isMaintenanceScheduleFlag && this.state.isMaintenanceScheduleFlag == OFF) {
      this.state.pages.splice(1, 0, {
        name: "next_maintenance",
        label: "nextMaintenanceLabel",
        component: () => (
          <MaintenanceSchedule
            vehicle={this.props.vehicle}
            // serviceSchedulesByVehicle={this.props.maintenanceIntervals.service_schedules_by_vehicle}
            language={this.props.language}
            dispatch={this.props.dispatch}
          />
        )
      })
    }

    const { dispatch, vehicle } = this.props
    const { vehicleId, isGeotabSectionFlag } = this.state
    if (isGeotabSectionFlag === ON && vehicle && vehicle.geotab_device_id !== null) {
      this.fetchGeotabEngineData(dispatch, vehicleId)
      this.fetchGeotabTirePressureData(dispatch, vehicleId)
      this.fetchGeotabBatteryLifeData(dispatch, vehicleId)
      this.fetchGeotabFaultCodesData(dispatch, vehicleId)
    }
    this.fetchVehicleData(dispatch, vehicleId)
    this.fetchVehicleServices(dispatch, vehicleId)
    this.fetchGloveBoxData(dispatch, vehicleId)
    this.fetchStates(dispatch)
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: "RESET_VEHICLE_MAINTENANCE_HISTORIES"
    })
    dispatch({
      type: "UNLOAD_VEHICLE_SERVICES"
    })
    dispatch({ type: "CLEAR_DOCUMENT_UPLOAD_MESSAGE" })
  }

  afterSubmit(status, data) {
    if (status === "success") {
      this.onSubmitSuccess(data)
    } else {
      this.onSubmitFailure(data)
    }
  }

  fetchGloveBoxData = (dispatch, vehicleId) => {
    dispatch({
      type: "VEHICLE_GLOVEBOX_SAGA",
      payload: {
        vehicleId: vehicleId
      }
    })
  }

  fetchGeotabEngineData = (dispatch, vehicleId) => {
    dispatch({
      type: "VEHICLE_GEOTAB_ENGINE_OIL_INFO_LOAD_SAGA",
      payload: {
        vehicleId: vehicleId
      }
    })
  }

  fetchGeotabTirePressureData = (dispatch, vehicleId) => {
    dispatch({
      type: "VEHICLE_GEOTAB_TIRE_PRESSURE_INFO_LOAD_SAGA",
      payload: {
        vehicleId: vehicleId
      }
    })
  }

  fetchGeotabBatteryLifeData = (dispatch, vehicleId) => {
    dispatch({
      type: "VEHICLE_GEOTAB_BATTERY_LIFE_INFO_LOAD_SAGA",
      payload: {
        vehicleId: vehicleId
      }
    })
  }

  fetchGeotabFaultCodesData = (dispatch, vehicleId) => {
    dispatch({
      type: "VEHICLE_GEOTAB_FAULT_CODES__INFO_LOAD_SAGA",
      payload: {
        vehicleId: vehicleId
      }
    })
  }

  fetchVehicleData = (dispatch, vehicleId) => {
    dispatch({
      type: "VEHICLE_LOAD_SAGA",
      payload: {
        vehicleId: vehicleId,
        options: {
          dataType: "extended"
        }
      }
    })
  }

  fetchStates = (dispatch) => {
    const isShellCanada = isFleetMaintenanceHubCanada()
    dispatch({
      type: "FETCH_ALL_STATES",
      payload: {
        country: isShellCanada ? CANDA_COUNTRY_CODE : US_COUNTRY_CODE
      }
    })
  }

  fetchVehicleServices = (dispatch, vehicleId) => {
    this.setState({ isMaintenanceScheduleLoading: true })
    dispatch({
      type: "VEHICLE_LOAD_SERVICES_SAGA",
      payload: {
        vehicleId,
        page: 1
      },
      callback: this.afterVehicleLoadService
    })
  }

  afterVehicleLoadService = (message) => {
    if (message === "success") {
      this.setState({ isMaintenanceScheduleLoading: false })
    }
  }

  navigateToPolicies() {
    this.props.history.push({
      pathname: navigationPaths.settingsIndex()
    })
  }

  onMaintenanceApprovalSubmit(submissionData) {
    this.props.dispatch({
      type: "MAINTENANCE_APPROVAL_SAGA",
      payload: { submissionData: submissionData },
      callback: this.afterSubmit.bind(this)
    })
  }

  onMaintenanceUpdateSubmit(submissionData) {
    this.props.dispatch({
      type: "MAINTENANCE_UPDATE_SAGA",
      payload: { submissionData: submissionData },
      callback: this.afterSubmit.bind(this)
    })
  }

  onMaintenanceCancelationSubmit(submissionData) {
    this.props.dispatch({
      type: "MAINTENANCE_CANCELATION_SAGA",
      payload: { submissionData: submissionData },
      callback: this.afterSubmit.bind(this)
    })
  }

  onMaintenancePolicyDelete(policyId) {
    this.props.dispatch({
      type: "MAINTENANCE_POLICY_DELETE_SAGA",
      payload: { policyId: policyId },
      callback: this.afterSubmit.bind(this)
    })
  }

  onMaintenancePolicySubmit(formData) {
    let sagaType

    if (formData.id === null) {
      sagaType = "MAINTENANCE_POLICY_CREATE_SAGA"
    } else {
      sagaType = "MAINTENANCE_POLICY_UPDATE_SAGA"
    }

    this.props.dispatch({
      type: sagaType,
      payload: { formData: formData, t: this.props.t },
      callback: this.afterSubmit.bind(this)
    })
  }

  onSubmitFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })

    window.scrollTo(0, 0)
  }

  onSubmitSuccess(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })

    // NOTE: Refreshes "Active Maintenance" & "Maintenance Policies" data.
    this.fetchVehicleData()
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError, vehicle, vehicleDataType } = this.props

    // NOTE:  Prevents the component from attempting to render content body prior to API load.
    const vehiclePresent = Object.keys(vehicle).length !== 0

    return vehiclePresent && !isLoading && !isLoadingError && vehicleDataType === "extended"
  }

  handlePageChange = (page) => {
    this.setState({ activePage: page })
    this.props.dispatch({ type: "CLEAR_DOCUMENT_UPLOAD_MESSAGE" })
  }

  afterDeleteMaintenanceHistory = (status, data) => {
    if (status === "success") {
      this.onDeleteMaintenanceHistorySuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onDeleteMaintenanceHistorySuccess = (data) => {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestFailure = (data) => {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  renderContent() {
    const { vehicle, t } = this.props
    let { activePage, pages } = this.state

    if (this.shouldRenderContent()) {
      return (
        <div className="vehicles-container">
          <Alert
            message={t("noPoliciesLabel")}
            type={"warning"}
            visible={vehicle.policies && vehicle.policies.length === 0}
          />
          <Segment.Group>
            <Segment className="menu-container">
              <Menu pointing secondary stackable>
                {pages.map((page, index) => (
                  <Menu.Item
                    key={index}
                    name={t(page.label)}
                    active={page.name === activePage}
                    onClick={this.handlePageChange.bind(this, page.name)}
                    className={isFleetAdvise() ? "font-weight" : ""}
                  />
                ))}

                <Menu.Item position="right" className="skinny-menu-button">
                  <ScheduleServiceButton floated="right" vehicle={vehicle} />
                </Menu.Item>
              </Menu>
            </Segment>
            <Segment className="tab-container">
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column>
                    {/* <Header size="large">{vehicleHelpers.formattedName(vehicle)}</Header> */}
                    {/* <Divider hidden /> */}
                    {pages.find((page) => page.name === activePage).component()}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Segment.Group>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const {
      isLoading,
      isLoadingError,
      documentUploadMsg = "",
      documentUploadStatus = "",
      dispatch
    } = this.props
    const { alertMessage, alertType } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />
        <Alert
          message={documentUploadMsg}
          type={documentUploadStatus}
          visible={documentUploadMsg !== ""}
        />
        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class VehicleShow

const mapStateToProps = (state) => {
  const { application, services, users, vehicles, maintenanceIntervals } = state
  const {
    gloveBoxDocuments,
    geotabTirePressureInfo,
    geotabEngineOilLifeInfo,
    geotabBatteryLifeInfo,
    geotabFaultCodesInfo
  } = vehicles
  let driverLicence = {},
    vehicleInsurance = {},
    registration = {},
    customDocuments = [],
    tirePressureAndTemp = {},
    engineOil = {},
    batteryLife = {},
    faultData = {}

  if (gloveBoxDocuments) {
    const { gloveBox } = gloveBoxDocuments
    if (gloveBox && gloveBox.glovebox_items.length) {
      gloveBox.glovebox_items.forEach((item) => {
        if (item.document_type === DOCUMENT_TYPE.DRIVER_LICENSE) {
          driverLicence = item
        } else if (item.document_type === DOCUMENT_TYPE.VEHICLE_INSURANCE) {
          vehicleInsurance = item
        } else if (item.document_type === DOCUMENT_TYPE.REGISTRATION) {
          registration = item
        } else {
          customDocuments.push(item)
        }
      })
    }
    if (!isEmpty(geotabTirePressureInfo)) {
      tirePressureAndTemp = geotabTirePressureInfo && geotabTirePressureInfo.tirePressureData
    }
    if (!isEmpty(geotabEngineOilLifeInfo)) {
      engineOil = geotabEngineOilLifeInfo.engineOilLifeData
    }
    if (!isEmpty(geotabBatteryLifeInfo)) {
      batteryLife = geotabBatteryLifeInfo.batteryLifeData
    }
    if (!isEmpty(geotabFaultCodesInfo)) {
      faultData = geotabFaultCodesInfo && geotabFaultCodesInfo.faultCodesData
    }
  }
  return {
    isLoading: application.isLoading,
    isLoadingError: application.isLoadingError,
    services: services.services,
    users: users.users,
    vehicle: vehicles.vehicle,
    vehicleDataType: vehicles.vehicleDataType,
    vehicles: vehicles.vehicles,
    vehiclesDetails: state.vehicles,
    maintenanceIntervals: maintenanceIntervals,
    language: users.currentUser ? users.currentUser.language : "",
    driverLicence,
    vehicleInsurance,
    registration,
    customDocuments: orderBy(customDocuments, "id"),
    documentUploadMsg: gloveBoxDocuments && gloveBoxDocuments.uploadMessage,
    documentUploadStatus: gloveBoxDocuments && gloveBoxDocuments.uploadMessageStatus,
    engineOil: engineOil,
    tirePressureAndTemp: tirePressureAndTemp,
    batteryLife: batteryLife,
    faultData: faultData,
    currentUser: users.currentUser,
    faultCodeService: services.services.filter((service) => service.name === FAULT_CODE_INSPECTION)
  }
}

export default connect(mapStateToProps)(withTranslation("vehicle")(VehicleShow))

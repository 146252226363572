import React, { Component } from "react"
import * as userHelpers from "../../helpers/userHelpers"
import Select from "../../elements/Select"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { Segment, Grid } from "semantic-ui-react"
import { connect } from "react-redux"
import { CURRENT_ACCOUNT_ID, CURRENT_FLEET_ID } from "../../constants/application"

class ManageAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: parseInt(sessionHelpers.getApplicationCookie(CURRENT_ACCOUNT_ID)),
      current_fleet: parseInt(sessionHelpers.getApplicationCookie(CURRENT_FLEET_ID)),
      Value_for_account_title: "",
      Value_for_fleet_title: ""
    }
  }

  handleChange = (event, accounts) => {
    this.setState({
      value: event.target.value,
      Value_for_account_title: accounts.find((o) => o.id === event.target.value).name
    })
    this.props.dispatch({
      type: "ACCOUNT_FLEETS",
      payload: { accountId: event.target.value },
      callback: this.afterAccountChangeRequest.bind(this)
    })
  }

  handleFleetChange(fleet_id) {
    sessionStorage.removeItem("current_fleet")
    this.props.dispatch({
      type: "FLEET_IMPERSONATION_SAGA",
      payload: { fleetId: fleet_id },
      callback: this.afterImpersonationRequest.bind(this)
    })
  }

  afterImpersonationRequest(status, data) {
    sessionHelpers.setApplicationCookie(CURRENT_ACCOUNT_ID, this.state.value)
    sessionHelpers.setApplicationCookie(CURRENT_FLEET_ID, this.state.current_fleet)
    window.location.reload()
  }

  afterAccountChangeRequest(status, data) {
    const { account_fleets } = this.props
    if (account_fleets.length == 1) {
      this.handleFleetChange(account_fleets[0].id)
    }
  }

  render() {
    const { currentUser, fleet, account_fleets } = this.props

    var accountFleets
    if (
      fleet.account &&
      account_fleets.length == 0 &&
      this.state.current_fleet == fleet.id &&
      this.state.value == fleet.account.id
    ) {
      accountFleets = currentUser.user_account_fleets
    } else {
      accountFleets = account_fleets
    }

    return (
      <>
        {currentUser.user_accounts && currentUser.user_accounts.length > 0 ? (
          <Segment
            style={{ backgroundColor: "#dd1d21", margin: "auto", border: "2px solid white" }}
          >
            <Grid columns="equal" stackable>
              <Grid.Column>
                <span style={{ fontWeight: 700, color: "#fff" }}> Account </span>
                <Select
                  className={"fleetcor-select"}
                  placeholder={"Accounts  "}
                  options={
                    currentUser.user_accounts &&
                    userHelpers.selectAccountOptions(
                      currentUser.user_accounts.filter(
                        (a) => a.is_active === true && a.enrolled === true
                      ),
                      {
                        displayOtherID: true
                      }
                    )
                  }
                  onChange={(event) =>
                    event.target.value && this.handleChange(event, currentUser.user_accounts)
                  }
                  value={this.state.value}
                  valueForTitle={
                    this.state.Value_for_account_title == ""
                      ? fleet.account.name
                      : this.state.Value_for_account_title
                  }
                />
              </Grid.Column>
            </Grid>
            <Grid columns="equal" stackable>
              {accountFleets && accountFleets.length > 1 && (
                <Grid.Column>
                  <span style={{ fontWeight: 700, color: "#fff" }}> Fleet </span>
                  <Select
                    className={"fleetcor-select"}
                    placeholder={"Fleets"}
                    options={userHelpers.selectAccountFleetOptions(
                      accountFleets.filter((f) => f.is_active === true && f.enrolled === true),
                      {
                        displayOtherID: true
                      }
                    )}
                    onChange={(event) =>
                      event.target.value &&
                      this.handleFleetChange(event.target.value, accountFleets)
                    }
                    value={this.state.current_fleet}
                    valueForTitle={fleet.name}
                  />
                </Grid.Column>
              )}
            </Grid>
          </Segment>
        ) : (
          ""
        )}
      </>
    )
  }
} // class HeaderLink

export default connect()(ManageAccount)

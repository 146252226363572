import { navigationPaths } from "../constants/paths"
const {
  fmcUsers,
  fmcDashboard,
  fleetNew,
  fmcActiveOrders,
  fmcTransactions,
  transferVehicles,
  fmcVehicles
} = navigationPaths

export function isFmcPath() {
  const fmcPaths = [
    fmcUsers(),
    fmcDashboard(),
    fleetNew(),
    fmcActiveOrders(),
    fmcTransactions(),
    transferVehicles(),
    fmcVehicles(),
    /\/fleets\/\d+\/edit/
  ]

  return fmcPaths.filter((path) => window.location.pathname.match(path)).length > 0
}

// NOTE: 'regenerator-runtime/runtime' is needed for rootSaga. It must be at top of file. See https://github.com/redux-saga/redux-saga/issues/280 for more information.
import "regenerator-runtime/runtime"

import { all } from "redux-saga/effects"

import applicationSaga from "./applicationSaga"
import dashboardSaga from "./dashboardSaga"
import fleetSaga from "./fleetSaga"
import maintenanceApprovalSaga from "./maintenanceApprovalSaga"
import maintenancePolicySaga from "./maintenancePolicySaga"
import maintenanceIntervalSaga from "./maintenanceIntervalSaga"
import maintenanceHistorySaga from "./maintenanceHistorySaga"
import maintenanceReminderSaga from "./maintenanceReminderSaga"
import geotabSyncEventSaga from "./geotabSyncEventSaga"
import paymentMethodSaga from "./paymentMethodSaga"
import serviceRequestSaga from "./serviceRequestSaga"
import sessionSaga from "./sessionSaga"
import shopSaga from "./shopSaga"
import userSaga from "./userSaga"
import vehicleSaga from "./vehicleSaga"
import shopOrderSaga from "./shopOrderSaga"
import shopOrderActivityItemSaga from "./shopOrderActivityItemSaga"
import affiliationSaga from "./affiliationSaga"
import paymentActivitySaga from "./paymentActivitySaga"
import promotionalCodeSaga from "./promotionalCodeSaga"
import segment from "./segment"
export default function* rootSaga() {
  yield all([
    /* NOTE: Add other sagas here */
    applicationSaga(),
    dashboardSaga(),
    fleetSaga(),
    maintenanceApprovalSaga(),
    maintenanceIntervalSaga(),
    maintenancePolicySaga(),
    maintenanceHistorySaga(),
    maintenanceReminderSaga(),
    geotabSyncEventSaga(),
    paymentMethodSaga(),
    serviceRequestSaga(),
    sessionSaga(),
    shopSaga(),
    userSaga(),
    vehicleSaga(),
    shopOrderSaga(),
    shopOrderActivityItemSaga(),
    affiliationSaga(),
    paymentActivitySaga(),
    promotionalCodeSaga(),
    segment()
  ])
}

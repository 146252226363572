import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Button from "../../elements/Button"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import { useTranslation } from "react-i18next"
import { withRouter } from "react-router"
import { OTP_REGEX } from "../../utilities/stringHelpers"
import TextField from "../../elements/TextField"
import ErrorField from "../../elements/ErrorField"
import { withTranslation } from "react-i18next"
import Alert from "../../components/shared/Alert"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { setupSplitFlags, ON } from "../config/SplitClient"
import {
  CURRENT_USER_EMAIL,
  CURRENT_USER_PASSWORD,
  EMAIL,
  PASSWORD
} from "../../constants/application"
let sub = getSubdomain()
class MultiFactorAuthentication extends Component {
  constructor(props) {
    super(props)

    const locationStateData = this.props.location.state || {}

    this.state = {
      alertMessage: "",
      alertType: "default",
      isSubmitting: false,
      otp: "",
      formData: {},
      typingTimer: null,
      otp_error: "",
      verifyForm: false
    }
    // this.navigateTo = navigateTo.bind(this)
    // this.navigateToForgotPassword = navigateTo.bind(this, "forgotPassword")
    // this.navigateToSignUp = navigateTo.bind(this, "signUp")
    // this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: sub })
    const { sendOTP, formData, t, otpVerifyError, signUp } = this.props
    const { dispatch } = this.props
    sessionHelpers.setApplicationCookie(CURRENT_USER_EMAIL, formData.email)
    sessionHelpers.setApplicationCookie(CURRENT_USER_PASSWORD, formData.password)
    let split_flag =
      this.state.isMultifactorAuthenticationFlag && this.state.isMultifactorAuthenticationFlag == ON
        ? true
        : false
    if (!signUp && sendOTP && !otpVerifyError) {
      this.setState({ isSubmitting: true })
      dispatch({
        type: "SEND_OTP_SAGA",
        payload: {
          formData,
          t: t,
          subdomain: getSubdomain(),
          is_resend_otp: false,
          is_split_flag: split_flag
        },
        callback: this.afterSendOTP.bind(this, formData)
      })
    } else if (signUp && sendOTP && !otpVerifyError) {
      this.setState({ isSubmitting: true })
      dispatch({
        type: "SIGN_UP_SAGA",
        payload: {
          formData: formData,
          t: this.props.t,
          toggle: this.state.toggle,
          is_split_flag: split_flag
        },
        callback: this.afterSendOTP.bind(this, formData)
      })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.typingTimer)
  }

  afterSendOTP(formData, status, data) {
    this.setState({ formData: formData })
    if (status == "success") {
      this.setState({ isSubmitting: false })
      this.props.setError(data, status)
    } else {
      this.onFormSubmitFailure(data, status)
    }
  }

  onFormSubmitFailure(data, status) {
    this.setState({ isSubmitting: false })
    this.props.setError(data, status)
  }

  handleChange = (evt) => {
    const value = evt.target.value
    const name = evt.target.name
    this.setState({
      [evt.target.name]: value
    })
    clearTimeout(this.state.typingTimer)

    this.state.typingTimer = setTimeout(() => {
      if (value) {
        this.isFieldValueValid(name, value)
      }
    }, 500)
  }

  isFieldValueValid = (field, value) => {
    this.setState({ otp_error: "" })
    switch (field) {
      case "otp":
        const otp = value
        if (!OTP_REGEX.test(otp)) {
          this.setState({ otp_error: "Please Enter 6 digits OTP", verifyForm: false })
        } else {
          this.setState({ verifyForm: true })
        }
        break
    }
  }

  onFormSubmit = () => {
    const { formData, otp } = this.state
    if (Object.keys(formData).length === 0) {
      formData["email"] = sessionHelpers.getApplicationCookie(CURRENT_USER_EMAIL)
      formData["password"] = sessionHelpers.getApplicationCookie(CURRENT_USER_PASSWORD)
    }
    this.props.onFormSubmit(formData, otp)
  }

  resendOTP = () => {
    const { dispatch, t } = this.props
    const { formData } = this.state
    this.setState({ isSubmitting: true })
    if (Object.keys(formData).length === 0) {
      formData[EMAIL] = sessionHelpers.getApplicationCookie(CURRENT_USER_EMAIL)
      formData[PASSWORD] = sessionHelpers.getApplicationCookie(CURRENT_USER_PASSWORD)
    }
    dispatch({
      type: "SEND_OTP_SAGA",
      payload: { formData, t: t, subdomain: getSubdomain(), is_resend_otp: true },
      callback: this.afterReSendOTP.bind(this)
    })
  }

  afterReSendOTP = (status, data) => {
    this.props.setError(data, status, true)
    this.setState({ isSubmitting: false, otp_error: "", otp: "" })
  }

  render() {
    const { otp, otp_error, alertMessage, alertType, isSubmitting, verifyForm } = this.state
    const { t } = this.props
    return (
      <>
        <LoadingThrobber visible={isSubmitting} />
        {!isSubmitting && (
          <>
            <div className={"sign-in-form"}>
              <p className="otpVerficationText"> Verification </p>
              <p> You will get a OTP via Email </p>
              <div className={"sign-in-email"}>
                <TextField
                  className={"sign-in-input"}
                  onChange={this.handleChange}
                  placeholder={t("common:otpLabel")}
                  value={otp}
                  name="otp"
                />
                <ErrorField
                  displayError={otp_error}
                  className={"error-field otp_error"}
                  label={otp_error}
                />
              </div>
              <div>
                <Button
                  className={"button--submit"}
                  label={t("common:validateOtp")}
                  onClick={this.onFormSubmit}
                  disabled={!verifyForm}
                  style={{ marginBottom: "12px", marginTop: "10px" }}
                />
              </div>
              <p>
                {" "}
                Didn't receive the verification OTP?{" "}
                <button className={"link"} onClick={this.resendOTP}>
                  Resend Again
                </button>
              </p>
            </div>
          </>
        )}
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  userSelectedLanguage: state.users ? state.users.userSelectedLanguage : ""
})
export default connect(mapStateToProps)(
  withRouter(withTranslation("common")(MultiFactorAuthentication))
)

const startYear = 1980
const numYears = 40
export const AMERICA = "Etc/GMT+5"
export const years = [...Array(numYears).keys()].map((i) => {
  const num = i + startYear
  return { label: String(num), value: num }
})

export const Date_Format = Object.freeze({
  YYYY_MM_DD: "YYYY-MM-DD",
  MMMM_YYYY: "MMMM YYYY",
  MM_DD_YYYY: "MM-DD-YYYY",
  M_D_YY: "M/D/YY",
  MM_DD_YY: "MM/DD/YYYY",
  HH_MM_A: "hh:mmA",
  MM_DD_YY_HMA: "MM/DD/YY, h:mmA",
  DEFAULT_UTC_DATE_TIME: "YYYY-MM-DD 00:00:00 UTC",
  YYYY_MM_01: "YYYY-MM-01",
  L: "L"
})

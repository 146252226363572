import React from "react"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { Grid } from "semantic-ui-react"

function DashboardShimmerEffect() {
  const gridColumns = Array.from({ length: 2 }, (_, index) => {
    return (
      <Grid.Column className="dashboard-cols">
        <ShimmerThumbnail height={200} width={350} rounded />
      </Grid.Column>
    )
  })

  const gridRows = Array.from({ length: 2 }, (_, index) => {
    return <Grid.Row stretched>{gridColumns}</Grid.Row>
  })

  return (
    <Grid columns="equal" stackable className="dashboard-shimmer-effect">
      <Grid.Row verticalAlign="center" className="row-shimmer">
        <Grid.Column>
          <div className="shimmer-btn-float">
            <ShimmerThumbnail height={30} width={150} rounded />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="shimmer-padding">
        <Grid.Column>
          <Grid columns="equal" stackable className="grid-flex-direction">
            {gridRows}
          </Grid>
        </Grid.Column>
        <Grid.Column width={5}>
          <ShimmerThumbnail height={700} width={350} rounded />{" "}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default DashboardShimmerEffect

import React from "react"
import { SCHEDULED_STATUSES, AWAITING_APPROVAL, APPROVAL } from "../../constants/statuses"
import { Segment, Table, Container, Header, Grid, Divider } from "semantic-ui-react"
import { RadialBarChart, RadialBar, Legend, Label, PolarRadiusAxis } from "recharts"
import { getStyles } from "../shared/StylesheetInjector"
import { navigationPaths } from "../../constants/paths"
import { Link } from "react-router-dom"
import * as maintenanceHelpers from "../../helpers/maintenanceHelpers"
import { PAST_DUE, DUE_SOON, DUE_NOW } from "../../helpers/maintenanceHelpers"
import { useTranslation } from "react-i18next"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"

function RadialBarChartWrapper({ label, count, total }) {
  const styles = getStyles()

  // This kind of graph always takes the highest value row and makes a circular bar at 100% for that one,
  // then all others are a % of that based on their relative magnitude.
  // So we have to make a hidden(via the fill) "total" bar to have the "real" bar be properly full.
  const data = [
    { name: "Count", value: count, fill: styles.rechartsPrimaryColor },
    { name: "Hidden Total Row", value: total || 1, fill: "#fff" }
  ]
  const heightAndWidth = 188

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        <RadialBarChart
          width={heightAndWidth}
          height={heightAndWidth}
          innerRadius="65%"
          outerRadius="80%"
          data={data}
          startAngle={225}
          endAngle={-45}
        >
          <RadialBar background clockWise={true} dataKey="value" isAnimationActive={false} />
          <Legend
            verticalAlign="middle"
            align="center"
            content={
              <Container
                textAlign="center"
                style={{
                  color: count > 0 ? styles.rechartsPrimaryColor : styles.rechartsNeutralColor,
                  fontSize: "36px"
                }}
              >
                {count}
              </Container>
            }
          />
        </RadialBarChart>
      </div>
      <Container textAlign="center" style={{ marginTop: -heightAndWidth / 5 }}>
        {label}
      </Container>
    </React.Fragment>
  )
}

export default function MaintenanceWidget({ maintenanceIntervals, vehicles }) {
  const { t } = useTranslation("dashboard")
  const maintenanceData = maintenanceHelpers.upcomingMaintenanceData({
    vehicles,
    serviceSchedulesByVehicle: maintenanceIntervals.service_schedules_by_vehicle
  })
  const pastDue = maintenanceData.filter(({ maintenance, type, vehicle }) => type === PAST_DUE)
    .length
  const dueSoon = maintenanceData.filter(({ maintenance, type, vehicle }) =>
    [DUE_SOON, DUE_NOW].includes(type)
  ).length

  return (
    <React.Fragment>
      <span />
      <Header size="small" className={"dash-hd hide-margin-on-computer-up"}>
        {t("preventativeMaintenanceLabel")}
      </Header>
      <Segment>
        <Grid columns="equal" stackable>
          <Grid.Column>
            <RadialBarChartWrapper
              count={pastDue}
              total={vehicles.length}
              label={t("pastDueLabel")}
            />
          </Grid.Column>
          <Grid.Column>
            <RadialBarChartWrapper
              count={dueSoon}
              total={vehicles.length}
              label={t("dueSoonLabel")}
            />
          </Grid.Column>
        </Grid>
        <Divider hidden />
        <Link
          to={{
            pathname: navigationPaths.servicesIndex(),
            state: {
              startingPage: "upcoming"
            }
          }}
        >
          <Container textAlign="center">
            <span className={`link ${isFleetAdvise() ? "dashboard-link-color" : ""}`}>
              {t("viewAllUpcomingIntervalsLabel")}
            </span>
          </Container>
        </Link>
      </Segment>
    </React.Fragment>
  )
}

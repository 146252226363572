import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { navigationPaths } from "../../constants/paths"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import MiniLoader from "../../components/shared/MiniLoader"
import { Dropdown, Segment, Header, Divider, Input } from "semantic-ui-react"
import { getStyles } from "../../components/shared/StylesheetInjector"
import ApprovalsHistory from "./ApprovalsHistory"
import { Approvals, constants } from "caradvise_shared_components"
import {
  formattedApprovalContacts,
  formattedCancelationContacts,
  eligibleForCancelation
} from "../../helpers/activeMaintenanceHelpers"
import * as phoneNumberHelpers from "../../helpers/phoneNumberHelpers"
import * as numberHelpers from "../../helpers/numberHelpers"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import { isTBC, isWex, isBraintree } from "../../helpers/userHelpers"
import { Redirect } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil } from "@fortawesome/pro-light-svg-icons"
import { formattedAddress } from "../../helpers/shopHelpers"
import { isFleetAdvise, isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { withTranslation } from "react-i18next"
import PromotionalCodeLink from "../../components/services/PromotionalCodeLink"
import PaymentsIndex from "../payments/PaymentsIndex"
import CustomNotification from "../../components/shared/CustomNotification"
import { NOTIFICATION_TYPE } from "../../constants/notification"
import MaintenanceHistorySummary from "./MaintnenaceHistorySummary"
import { faTimes } from "@fortawesome/pro-regular-svg-icons"
import { isDriver } from "../../helpers/userRoleHelpers"
import { isNull } from "lodash"
import ApproversNotesHistory from "./ApproversNotesHistory"
import ApprovalsShowShimmer from "./ApprovalsShowShimmer"
import { NA } from "../../constants/paymentActivity"
const { ApprovalsEmptyState, ApprovalsForm, ApprovalsInfoModal } = Approvals
const serviceStatuses = constants.services.statuses
let styles = getStyles()
const { INITIATED_BY_USER, ACTIVE, AWAITING_APPROVAL } = constants.orders
styles = { ...styles, primaryColor: styles.carAdviseOrange }

class ApprovalsShow extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    order: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      alertMessage: "",
      alertType: "default",
      editingPo: false,
      poNumber: (props.order || {}).po_number || "",
      isSubmitting: false,
      infoModalOpen: true,
      selectedPaymentProvider: "",
      showMaintenanceHistory: false,
      isVanityLabelSubmitting: {},
      isApprovalNotesSubmitting: false
    }
    this.props.services &&
      this.props.services.map((service) => {
        this.state.isVanityLabelSubmitting[service.id] = false
      })
  }

  componentDidMount() {
    this.fetchOrderData({ withPriceRanges: true })
  }

  onCancel = () => {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "MAINTENANCE_CANCELATION_SAGA",
      payload: {
        submissionData: {
          id: this.props.order.id,
          status: "cancel"
        }
      },
      callback: this.afterSubmit
    })
  }

  updateLoadingState = (id, newValue) => {
    this.setState((prevState) => ({
      isVanityLabelSubmitting: { ...prevState.isVanityLabelSubmitting, [id]: newValue }
    }))
  }

  onVanitySubmit = (index, service, vanityLabel) => {
    const { dispatch, t } = this.props

    this.updateLoadingState(index, true)
    const response = dispatch({
      type: "CREATE_AND_UPDATE_VANITY_LABEL_SAGA",
      payload: {
        order_service_id: service.id,
        shop_order_id: service.order_id,
        vanity_label: vanityLabel,
        isNew: isNull(service.vanity_label),
        t: t,
        index: index
      },
      callback: this.afterRequest.bind(this)
    })
  }

  afterRequest(status, data) {
    if (status === NOTIFICATION_TYPE.SUCCESS) {
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onRequestFailure(data) {
    const { type, title, message, index } = data
    this.updateLoadingState(index, false)
    this.setState({ isApprovalNotesSubmitting: false })
    const { t } = this.props
    return CustomNotification(type, title, message, t)
  }

  onRequestSuccess(data) {
    const { type, title, message, index } = data
    this.updateLoadingState(index, false)
    this.setState({ isApprovalNotesSubmitting: false })
    const { t } = this.props
    return CustomNotification(type, title, message, t)
  }

  onSubmit = (services, customerComments, creditCardId, approverNote) => {
    const { currentUser, order, t } = this.props
    const { fleet_payment_providers, pays_through_platform, wex_account_number } = currentUser
    const { selectedPaymentProvider, poNumber } = this.state

    let paymentProvider =
      fleet_payment_providers.length > 1
        ? selectedPaymentProvider
        : fleet_payment_providers[0] || ""

    if (pays_through_platform) {
      if (paymentProvider) {
        if (paymentProvider === "braintree" && creditCardId === null) {
          CustomNotification(
            NOTIFICATION_TYPE.DANGER,
            t("common:approvalErrorLabel"),
            t("common:selectCardErrorLabel"),
            t
          )
          return
        } else if (paymentProvider === "wex" && !wex_account_number) {
          CustomNotification(
            NOTIFICATION_TYPE.DANGER,
            t("common:approvalErrorLabel"),
            t("common:selectWexErrorLabel"),
            t
          )
          return
        }
      } else {
        CustomNotification(
          NOTIFICATION_TYPE.DANGER,
          t("common:approvalErrorLabel"),
          t("common:noPaymentProvider"),
          t
        )
        return
      }
    }
    this.setState({ isSubmitting: true }, () => {
      if (approverNote && approverNote.length > 0) {
        this.setState({ isApprovalNotesSubmitting: true })
        this.props.dispatch({
          type: "SHOP_ORDER_APPROVERS_NOTES_CREATE_SAGA",
          payload: {
            shopOrderId: order.id,
            shopId: order.shop_id,
            approverNote: approverNote,
            t: t
          }
        })
      }
      this.props.dispatch({
        type: "MAINTENANCE_APPROVAL_SAGA",
        payload: {
          submissionData: {
            id: order.id,
            order_services: services,
            customer_comments: customerComments,
            po_number: poNumber ? poNumber : order.poNumber,
            payment_token: creditCardId,
            payment_type: paymentProvider
          }
        },
        callback: this.afterSubmit
      })
    })
  }

  afterSubmit = (status, data) => {
    if (status === "success") {
      this.onSubmitSuccess(data)
    } else {
      this.onSubmitFailure(data)
    }
  }

  onApprovalChange = async (services) => {
    const { order, currentUser } = this.props
    await this.setState({ loadingSums: true })
    await this.setState({ isSubmitting: true })
    this.fetchOrderData({ services: services })
  }

  afterShopOrderLoad = async () => {
    this.setState({ loadingSums: false })
    this.setState({ isSubmitting: false })
  }

  fetchOrderData = (options = {}) => {
    this.props.dispatch({
      type: "SHOP_ORDER_LOAD_SAGA",
      payload: {
        shopOrderId: this.props.match.params.id,
        orderServices: options.services,
        priceAsApprovalsLogic: true,
        withPriceRanges: options.withPriceRanges
      },
      callback: this.afterShopOrderLoad
    })
  }

  onSubmitFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType,
      isSubmitting: false,
      isApprovalNotesSubmitting: false
    })

    window.scrollTo(0, 0)
  }

  onSubmitSuccess(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType,
      isSubmitting: false,
      submitted: true,
      isApprovalNotesSubmitting: false
    })
  }

  toggleEditingPo = () => this.setState({ editingPo: !this.state.editingPo })
  setPo = (e) => this.setState({ poNumber: e.target.value })
  closeInfoModal = () => this.setState({ infoModalOpen: !this.state.infoModalOpen })

  maintenaceHistoryPageView = () => {
    this.setState({ showMaintenanceHistory: true })
    const section = document.getElementById("maintenance-summary-section")

    if (section.style.display === "none") section.style.display = "block"
    else section.style.display = "none"

    if (window.innerWidth <= 1138) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth"
      })
    }
  }

  approvalsInfoModal = () => {
    const { infoModalOpen } = this.state
    const { order, services } = this.props

    return (
      <ApprovalsInfoModal
        order={order}
        services={services}
        infoModalOpen={infoModalOpen}
        closeInfoModal={this.closeInfoModal}
        t={this.props.t}
      />
    )
  }

  handleSubmitPoNumber = () => {
    const { order, dispatch } = this.props

    this.setState({ isSubmitting: true })
    dispatch({
      type: "MAINTENANCE_UPDATE_SAGA",
      payload: {
        submissionData: { id: order.id, po_number: this.state.poNumber }
      },
      callback: () => this.setState({ isSubmitting: false, editingPo: false })
    })
  }

  handleCancelPoNumberEdit = () => {
    this.setState({ editingPo: false, poNumber: this.props.order.po_number || "" })
  }

  renderPoNumber = () => {
    const { order, t } = this.props
    const { editingPo, poNumber, isSubmitting } = this.state

    if (editingPo) {
      return (
        <>
          <Input value={poNumber || order.po_number} onChange={this.setPo} />
          {isSubmitting ? (
            <MiniLoader inverted={false} />
          ) : (
            <>
              <span
                style={{ paddingLeft: "7px" }}
                className="link"
                disabled={!isSubmitting}
                onClick={this.handleSubmitPoNumber}
              >
                {t("common:saveLabel")}
              </span>
              &nbsp;&nbsp;
              <span className="link" onClick={this.handleCancelPoNumberEdit}>
                {t("common:cancelLabel")}
              </span>
            </>
          )}
        </>
      )
    } else {
      return (
        <>
          {order.po_number || NA}
          <FontAwesomeIcon
            style={{ paddingLeft: "7px", cursor: "pointer" }}
            icon={faPencil}
            onClick={this.toggleEditingPo}
          />
        </>
      )
    }
  }

  orderInfo = () => {
    const { order, users, t } = this.props
    const { shop } = order || {}
    const currentUser = this.props.currentUser || this.state.transitionalCurrentUser || {}
    const user = order.originator || {}

    return (
      <>
        <div className="inline-elements">
          <Header size="large" className="title">
            {vehicleHelpers.formattedName(order.vehicle)}
          </Header>
          {!isDriver(currentUser) ? (
            <div className="action">
              <span className="link" onClick={() => this.maintenaceHistoryPageView()}>
                {t("vehicle:viewMaintenanceHistoryLbl")}{" "}
              </span>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <p>
          {t("dashboard:shopOrderId")}: {order.id}
        </p>
        <p>
          {t("dashboard:otherIdLabel")}: {order.vehicle.other_id || NA}
        </p>
        <p>
          {t("poNumberLabel")}:{this.renderPoNumber()}
        </p>
        <p>
          <div>
            <strong>{t("shopLabel")}:</strong>
          </div>
          {`${shop.name} - ${formattedAddress(shop)}`}
          <div className={"active_order__detail"}>
            {phoneNumberHelpers.reformat(shop.main_phone_number)}
          </div>
        </p>
        <p>
          <div>
            <strong>{t("appointmentLabel")}:</strong>
          </div>
          {`${order.appointment_date_pretty} ${order.appointment_time_pretty}`}
        </p>
        <p>
          <div>
            <strong>{t("odometerLabel")}: </strong>

            {isFleetMaintenanceHubCanada()
              ? `${numberHelpers.formatWithCommas(order.kilometers)} km`
              : `${numberHelpers.formatWithCommas(order.miles)} mi`}
          </div>
        </p>
        <p>
          <div>
            <strong>{t("customerLabel")}: </strong>
            {user.name}
          </div>
        </p>
        <p>
          <div>
            <strong>{t("cellLabel")}: </strong>
            {phoneNumberHelpers.reformat(user.cellPhone)}
          </div>
        </p>
        <p>
          <div>
            <strong>{t("emailLabel")}: </strong>
            {user.email}
          </div>
        </p>
        <p>
          <div>
            <strong>{t("userRolesLabel")}: </strong>
            {user.roles.map((r) => (typeof r === "string" ? r : r.name)).join(", ")}
          </div>
        </p>
        <Divider hidden />
        {order.state === AWAITING_APPROVAL && !order.can_approve && (
          <Segment>
            <p>{t("notEligibleToApproveLabel")}:</p>
            {formattedApprovalContacts(order, users)
              .split("\n")
              .map((c) => (
                <p>{c}</p>
              ))}
          </Segment>
        )}
        <Divider hidden />
      </>
    )
  }

  onPaymentProviderChange = (value) => {
    this.setState({
      selectedPaymentProvider: value
    })
  }

  wexPaymentsPage() {
    return {
      name: "wex",
      label: "wexPaymentLabel"
    }
  }

  braintreePaymentsPage() {
    return {
      name: "braintree",
      label: "creditCardLabel"
    }
  }

  fleetcorPaymentsPage() {
    return {
      name: "fleetcor",
      label: "fleetcorLabel"
    }
  }

  selectPaymentProvidersView() {
    const { currentUser } = this.props
    const { fleet_payment_providers } = currentUser
    let pages = []
    if (fleet_payment_providers && fleet_payment_providers.includes("braintree"))
      pages.push(this.braintreePaymentsPage())
    if (fleet_payment_providers && fleet_payment_providers.includes("wex"))
      pages.push(this.wexPaymentsPage())
    if (fleet_payment_providers && fleet_payment_providers.includes("fleetcor"))
      pages.push(this.fleetcorPaymentsPage())
    return (
      <PaymentsIndex
        currentUser={currentUser}
        pages={pages}
        fromApproval={true}
        onChange={this.onPaymentProviderChange}
        {...this.props}
      />
    )
  }

  onApproversNotesSubmit = (order, approverNote) => {
    const { dispatch, t } = this.props
    const { id, shop_id } = order

    this.setState({ isApprovalNotesSubmitting: true })
    dispatch({
      type: "SHOP_ORDER_APPROVERS_NOTES_CREATE_SAGA",
      payload: {
        shopOrderId: id,
        shopId: shop_id,
        approverNote: approverNote,
        t: t
      },
      callback: this.afterRequest.bind(this)
    })
  }

  renderContent() {
    const { users, order, services, priceRanges, t, i18n } = this.props
    const currentUser = this.props.currentUser || this.state.transitionalCurrentUser || {}
    const {
      pays_through_platform,
      has_payment_method,
      fleet_payment_providers,
      wex_account_number
    } = currentUser
    const {
      isSubmitting,
      submitted,
      loadingSums,
      editingPo,
      poNumber,
      selectedPaymentProvider,
      showMaintenanceHistory,
      isVanityLabelSubmitting,
      isApprovalNotesSubmitting
    } = this.state
    const RejectionReasonsDropdown = (
      <Dropdown
        className="rejection-reasons-dropdown"
        placeholder={t("seletRejectionReasonLabel")}
        fluid
        selection
      />
    )
    const CreditCardDropdown = (
      <Dropdown className="credit-card-dropdown" placeholder="Select Credit Card" fluid selection />
    )

    if (!services || currentUser.id == null) return null

    if (submitted) return <Redirect to={navigationPaths.dashboard()} />

    if (services.length > 0) {
      const {
        braintree_payment_methods,
        check_payment_method_of_previous_transaction,
        pre_tax_sub_total,
        tax_amount,
        previous_payments
      } = order
      if (braintree_payment_methods.length > 0) {
        braintree_payment_methods[0].default = true
        currentUser.has_cc_info_saved = true
      } else if (
        fleet_payment_providers &&
        fleet_payment_providers.length == 1 &&
        isBraintree(currentUser) &&
        braintree_payment_methods.length == 0
      ) {
        currentUser.has_cc_info_saved = false
      }

      if (fleet_payment_providers && fleet_payment_providers.includes("fleetcor")) {
        currentUser.has_cc_info_saved = true
      }
      // To manage the refund scenario
      if (
        order &&
        check_payment_method_of_previous_transaction &&
        Number(pre_tax_sub_total) + Number(tax_amount) - Number(previous_payments) <= 0
      ) {
        if (check_payment_method_of_previous_transaction.toLowerCase() == "braintree") {
          currentUser.fleet_payment_providers = ["braintree"]
        }
        if (check_payment_method_of_previous_transaction.toLowerCase() == "wex") {
          currentUser.fleet_payment_providers = ["wex"]
        }
        if (check_payment_method_of_previous_transaction.toLowerCase() == "fleetcor") {
          currentUser.fleet_payment_providers = ["fleetcor"]
        }
      }

      return (
        <div>
          <span />
          <Header size="small" className={"dash-hd"}>
            {t("reviewShopOrderLabel")}
          </Header>
          <section className="container-approvals">
            <div className="box-container">
              <div style={{ backgroundColor: "white" }}>
                <ApprovalsForm
                  allCreditCardsInfo={braintree_payment_methods}
                  isSubmitting={isSubmitting}
                  onSubmit={this.onSubmit}
                  onCancel={this.onCancel}
                  eligibleForCancelation={eligibleForCancelation(order)}
                  currentUserCanCancel={order.can_cancel}
                  formattedCancelationContacts={formattedCancelationContacts(order, users)}
                  order={order}
                  services={services}
                  user={currentUser}
                  paymentListEl={
                    pays_through_platform && (
                      <React.Fragment>
                        <span>{t("addPaymentInSettingsLabel")}</span>
                      </React.Fragment>
                    )
                  }
                  styles={styles}
                  hideChargeMessage={!pays_through_platform}
                  pays_through_platform={pays_through_platform}
                  paymentMethods={pays_through_platform && braintree_payment_methods}
                  hasPaymentMethod={has_payment_method}
                  paymentProviderTypes={fleet_payment_providers}
                  hideSavings={true}
                  hideRetailPricing={true}
                  hidePriceRange={isTBC(currentUser)}
                  hidePricing={[INITIATED_BY_USER, ACTIVE].includes(order.state)}
                  priceRanges={priceRanges}
                  customHeader={this.orderInfo()}
                  canApprove={order.can_approve}
                  loadingSums={!!loadingSums}
                  onApprovalChange={this.onApprovalChange}
                  customerCommentsCharLimit={150}
                  rejectionReasonsDropdown={RejectionReasonsDropdown}
                  CreditCardDropdown={CreditCardDropdown}
                  SelectPaymentProvidersView={this.selectPaymentProvidersView()}
                  selectedPaymentProvider={selectedPaymentProvider}
                  wex_account_number={wex_account_number}
                  t={t}
                  i18n={i18n}
                  onVanitySubmit={this.onVanitySubmit}
                  isVanityLabelSubmitting={isVanityLabelSubmitting}
                  canEditVanity={!isDriver(currentUser)}
                  onApproversNotesSubmit={this.onApproversNotesSubmit}
                  isApprovalNotesSubmitting={isApprovalNotesSubmitting}
                  approverNotesCharLimit={500}
                  promotionalCodeLink={
                    <PromotionalCodeLink
                      shop_order_id={order.id}
                      onApprovalChange={this.onApprovalChange}
                      services={services}
                      order={order}
                    />
                  }
                />

                {/*
                Only exposing approvals history if pays_through_platform, since the backend's
                calculation of the order totals _might_ be slightly different and need to adjust
                for those that don't pay through platform.
              */}
                {pays_through_platform && (
                  <ApprovalsHistory shopOrderId={this.props.match.params.id} />
                )}
                <ApproversNotesHistory shopOrderId={this.props.match.params.id} />
              </div>
            </div>
            {
              <div
                className="box-container maintenance-summary-section"
                style={{ display: "none" }}
                id="maintenance-summary-section"
              >
                <React.Fragment>
                  <div className="inline-elements">
                    <Header as="h3" className="title">
                      {t("vehicle:maintenanceHistoryLabel")}
                    </Header>
                    <div className="action">
                      <FontAwesomeIcon
                        icon={faTimes}
                        size={"2x"}
                        onClick={() => this.maintenaceHistoryPageView()}
                      />
                    </div>
                  </div>
                  {showMaintenanceHistory && (
                    <MaintenanceHistorySummary
                      afterDeleteMaintenanceHistory={this.afterDeleteMaintenanceHistory}
                      {...this.props}
                    />
                  )}
                </React.Fragment>
              </div>
            }
          </section>
        </div>
      )
    } else {
      return (
        <div>
          <span />
          <Header size="small" className={"dash-hd"}>
            {t("reviewShopOrderLabel")}
          </Header>
          <Segment>
            <ApprovalsEmptyState t={this.props.t} />
          </Segment>
        </div>
      )
    }
  }

  render() {
    const { isLoading, isLoadingError, t } = this.props

    const { alertMessage, alertType, loadingSums } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        {isLoading &&
          !loadingSums &&
          (isFleetAdvise() ? (
            <ApprovalsShowShimmer />
          ) : (
            <LoadingThrobber visible={isLoading && !loadingSums} />
          ))}

        <LoadingError visible={!isLoading && isLoadingError} />

        {(!isLoading || loadingSums) && !isLoadingError && (
          <div>
            {this.approvalsInfoModal()}
            {this.renderContent()}
          </div>
        )}
      </ApplicationLayout>
    )
  }
} // class ApprovalsShow

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isVehicleMHComplete: state.vehicles.isVehicleMHComplete,
  isLoadingError: state.application.isLoadingError,
  users: state.users.users,
  order: state.shopOrders.shopOrder,
  services:
    state.shopOrders.shopOrder &&
    state.shopOrders.shopOrder.order_services &&
    state.shopOrders.shopOrder.order_services.filter(
      (service) => ![serviceStatuses["deleted"]].includes(service.status)
    ),
  priceRanges: state.shopOrders.priceRanges,
  currentUser: state.users.currentUser || state.application.userSessionData,
  vehicle: state.shopOrders.shopOrder && state.shopOrders.shopOrder.vehicle
})

export default connect(mapStateToProps)(
  withTranslation([
    "shopOrders",
    "approvalsEmptyState",
    "approvalsForm",
    "approvalsInfoModal",
    "vehicle"
  ])(ApprovalsShow)
)

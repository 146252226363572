import React from "react"
import {
  SCHEDULED_STATUSES,
  AWAITING_APPROVAL,
  APPROVAL,
  ACTIVE_STATUSES
} from "../../constants/statuses"
import { getStyles } from "../shared/StylesheetInjector"
import { Segment, Table, Container, Header, Divider } from "semantic-ui-react"
import { navigationPaths } from "../../constants/paths"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { isFleetAdvise } from "../../helpers/affiliationHelpers"

const styles = getStyles()

function OrderLink({ children, statuses }) {
  return (
    <Link
      to={{
        pathname: navigationPaths.servicesIndex(),
        state: { startingPage: "active", orderFilter: statuses }
      }}
      style={{ color: "unset" }}
    >
      {children}
    </Link>
  )
}

export default function OrderStatusWidget({ orders }) {
  const { t } = useTranslation("dashboard")

  let active = orders.filter((o) => SCHEDULED_STATUSES.indexOf(o.status) !== -1).length
  let awaiting = orders.filter((o) => o.status === AWAITING_APPROVAL).length
  let approved = orders.filter((o) => o.status === APPROVAL).length
  return (
    <React.Fragment>
      <span />
      <Header size="small" className={"dash-hd hide-margin-on-computer-up"}>
        {t("shopOrdersLabel")}
      </Header>
      <Segment>
        <Table className="borderless" padded="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <OrderLink statuses={SCHEDULED_STATUSES}>{t("activeOrdersLabel")}</OrderLink>
              </Table.Cell>
              <Table.Cell
                style={{
                  color: active > 0 ? styles.rechartsPrimaryColor : styles.rechartsNeutralColor
                }}
              >
                <OrderLink statuses={SCHEDULED_STATUSES}>
                  <b>{active}</b>
                </OrderLink>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <OrderLink statuses={[AWAITING_APPROVAL]}>{t("needsApprovalLabel")}</OrderLink>
              </Table.Cell>
              <Table.Cell
                style={{
                  color: awaiting > 0 ? styles.rechartsNegativeColor : styles.rechartsNeutralColor
                }}
              >
                <OrderLink statuses={[AWAITING_APPROVAL]}>
                  <b>{awaiting}</b>
                </OrderLink>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <OrderLink statuses={[APPROVAL]}>{t("approvedLabel")}</OrderLink>
              </Table.Cell>
              <Table.Cell
                style={{
                  color: approved > 0 ? styles.rechartsPrimaryColor : styles.rechartsNeutralColor
                }}
              >
                <OrderLink statuses={[APPROVAL]}>
                  <b>{approved}</b>
                </OrderLink>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Link
          to={{
            pathname: navigationPaths.servicesIndex(),
            state: {
              startingPage: "active"
            }
          }}
        >
          <Container textAlign="center">
            <span className={`link ${isFleetAdvise() ? "dashboard-link-color" : ""}`}>
              {t("viewAllActiveOrdersLabel")}
            </span>
          </Container>
        </Link>
      </Segment>
    </React.Fragment>
  )
}

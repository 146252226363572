import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import EditModal from "../shared/EditModal"
import * as vehicleHelpers from "../../helpers/vehicleHelpers"
import * as userHelpers from "../../helpers/userHelpers"
import { getSubdomain } from "../shared/StylesheetInjector"
import { isFleetcor, isFuelman } from "../../helpers/affiliationHelpers"
import {
  FMC,
  FMC_FLEET_ADMIN,
  FMC_FLEET_MANAGER,
  ADMIN,
  OWNER,
  DRIVER,
  MANAGER,
  FLEET_MANAGER,
  ACCOUNT_OWNER
} from "../../constants/roles"
import { sortBy } from "lodash"
import { withTranslation } from "react-i18next"

import { List } from "semantic-ui-react"
import { FLEET } from "../../constants/application"
import { StateNamesAndAbbreviations, ProvinceNamesAndAbbreviations } from "../../constants/states"
import { isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import { EDIT, USER_EDIT_OBJECT_NAME } from "../../constants/application"

const page_condition = window.location.href.indexOf("settings")

class UserModal extends Component {
  allFields = () => {
    const { isFMC, t, isFleetMode } = this.props
    const rolesHelper = (
      <List
        items={[
          "Owner: Owner of the Fleet.",
          "Admin: Administrator of the Fleet. Add users, vehicles and set policies.",
          "Driver: Fleet Driver. Can view assigned vehicle.",
          "Manager: Manager of the Fleet. Can set policies and assign drivers."
        ]}
      />
    )
    const fmcRolesHelper = (
      <List
        items={[
          "FMC: Owner of the Fleet.",
          "FMC Admin: Administrator of the Fleet. Add users, vehicles and set policies.",
          "FMC Manager: Manager of the Fleet. Can set policies and assign drivers."
        ]}
      />
    )
    const approvalFleetsHelper = (
      <div>
        When a vehicle gets service that exceeds dollar amounts set by policies, an FMC may need to
        approve. Select which fleets this user should be notified about.
      </div>
    )
    let opts = {
      1: [
        { placeholder: t("firstNameLabel"), optional: false, fieldName: "firstName" },
        { placeholder: t("lastNameLabel"), optional: false, fieldName: "lastName" },
        {
          placeholder: t("emailLabel"),
          optional: false,
          fieldName: "email",
          specialValidations: ["email"]
        },
        {
          placeholder: t("phoneNumberLabel"),
          optional: false,
          fieldName: "cellPhone",
          specialValidations: ["phone"]
        }
      ]
    }
    if (!isFMC) {
      opts[1].push({
        placeholder: t("rolesLabel"),
        optional: true,
        fieldName: "roles",
        valueSet: "userRoles",
        helpModal: rolesHelper
      })
      opts[1].push({
        placeholder: t("fleetsLabel"),
        optional: true,
        fieldName: "approvalFleetIds",
        valueSet: "fleets",
        helpModal: approvalFleetsHelper
      })
      opts[2] = [
        {
          placeholder: <strong>{t("textPreferencesLabel")}</strong>,
          optional: true,
          fieldName: "text_notification_preference_attributes"
        }
      ]

      opts[3] = [
        {
          placeholder: <strong>{t("emailPreferencesLabel")}</strong>,
          optional: true,
          fieldName: "email_notification_preference_attributes"
        }
      ]
    }
    if (isFMC) {
      opts[1].push({
        placeholder: t("rolesLabel"),
        fieldName: "roles",
        valueSet: "userRoles",
        helpModal: fmcRolesHelper
      })
      opts[1].push({
        placeholder: t("fleetsLabel"),
        optional: true,
        fieldName: "approvalFleetIds",
        valueSet: "fleets",
        helpModal: approvalFleetsHelper
      })
    }
    if (isFleetMode) {
      opts[1].push({
        placeholder: t("vehiclesLabel"),
        optional: true,
        fieldName: "vehicle_ids",
        valueSet: "vehicles"
      })
      opts[1].push({
        placeholder: t("common:addressLabel"),
        optional: true,
        fieldName: "address"
      })
      opts[1].push({
        placeholder: t("common:cityLabel"),
        optional: true,
        fieldName: "city"
      })
      opts[1].push({
        placeholder: t("common:stateLabel"),
        optional: true,
        fieldName: "state",
        valueSet: "states"
      })
      opts[1].push({
        placeholder: isFleetMaintenanceHubCanada() ? t("codePostalLabel") : t("zipCodeLabel"),
        optional: true,
        fieldName: "garaging_zip",
        specialValidations: ["zip"]
      })
      opts[2] = [
        {
          placeholder: <strong>{t("textPreferencesLabel")}</strong>,
          optional: true,
          fieldName: "text_notification_preference_attributes"
        }
      ]
      opts[3] = [
        {
          placeholder: <strong>{t("emailPreferencesLabel")}</strong>,
          optional: true,
          fieldName: "email_notification_preference_attributes"
        }
      ]
    }

    return opts
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    completionCallback: PropTypes.func.isRequired
  }

  static defaultProps = {
    user: {},
    completionCallback: () => {}
  }

  render() {
    var { user, userRoles, currentUser } = this.props
    const roles = [FMC_FLEET_ADMIN, FMC, FMC_FLEET_MANAGER]
    const without_fmc_roles = [ADMIN, OWNER, FMC]
    const fleetcor_roles = [
      ACCOUNT_OWNER,
      FLEET_MANAGER,
      DRIVER,
      FMC_FLEET_ADMIN,
      FMC,
      FMC_FLEET_MANAGER
    ]
    userRoles = userRoles.filter((value) => value == "FMC")
    return (
      <>
        {user.roles != undefined &&
        ((!isFleetcor() &&
          user.roles.some((r) => roles.indexOf(r) >= 0) &&
          currentUser.roles.some((r) => without_fmc_roles.indexOf(r) < 0)) ||
          (isFleetcor() && user.roles.some((r) => fleetcor_roles.indexOf(r) < 0))) ? (
          ""
        ) : (
          <EditModal
            objectName={USER_EDIT_OBJECT_NAME}
            object={user}
            allFields={this.allFields()}
            editFields={this.allFields()}
            saga={"USER_CREATE_OR_UPDATE_SAGA"}
            flag={EDIT}
            editSaga={"USER_EDIT_SAGA"}
            {...this.props}
          />
        )}
      </>
    )
  }
} // class UserModal

const mapStateToProps = (state, ownProps) => {
  var { userRoles } = state.users
  const { basicVehicles } = state.vehicles
  var fleets = state.fleets.all_fleets

  if (userRoles.length > 0 && !ownProps.isFMC) {
    const standardRoles = [ADMIN, OWNER, MANAGER, DRIVER, FLEET_MANAGER, ACCOUNT_OWNER]
    userRoles = userRoles.filter((role) => standardRoles.includes(role.name))

    if (isFleetcor() || isFuelman()) {
      const filterRoles = [DRIVER, FLEET_MANAGER, ACCOUNT_OWNER]
      if (window.location.href.includes("settings"))
        userRoles = userRoles.filter((role) => filterRoles.includes(role.name))
    }
  }
  if (userRoles.length > 0 && !window.location.href.includes("settings")) {
    const filterRoles = [FMC, FMC_FLEET_ADMIN, FMC_FLEET_MANAGER]
    userRoles = userRoles.filter((role) => filterRoles.includes(role.name))
  }
  return {
    userRoles: userRoles.map((role) => ({ key: role.id, value: role.name, text: role.name })) || [],
    vehicles: vehicleHelpers.selectOptions(basicVehicles, { semantic: true }),
    fleets: sortBy(fleets, "name").map((fleet) => {
      return { key: fleet.id, value: fleet.id, text: fleet.name }
    }),
    isFleetMode: state.application.mode === FLEET,
    states: (isFleetMaintenanceHubCanada()
      ? ProvinceNamesAndAbbreviations
      : StateNamesAndAbbreviations
    ).map((s) => ({
      key: s.abbreviation,
      value: s.abbreviation,
      text: s.name
    }))
  }
}

export default connect(mapStateToProps)(withTranslation("common")(UserModal))

import React, { Component } from "react"
import PropTypes from "prop-types"
import Button from "../../elements/Button"
import PasswordField from "../../elements/PasswordField"
import TextField from "../../elements/TextField"
import MobileStoreButton from "../../elements/MobileStoreButton"
import * as browserHelpers from "../../helpers/browserHelpers"
import { getSubdomain } from "../shared/StylesheetInjector"
import {
  isFleetAdvise,
  isFleetMaintenanceHub,
  isFleetMaintenanceHubCanada,
  isZubie
} from "../../helpers/affiliationHelpers"
import UserOrAdminSignIn from "./UserOrAdminSignIn"
import { elements } from "caradvise_shared_components"
import { withTranslation } from "react-i18next"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import ReCAPTCHA from "react-google-recaptcha"
import { OFF, setupSplitFlags } from "../config/SplitClient"
import { connect } from "react-redux"
import { EVENTS, REMEMBER_ME_COOKIE } from "../../constants/application"
const recaptchaRef = React.createRef()
const { PrivacyPolicyAndTerms } = elements

class SignInForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,

    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    isSubmitting: false
  }

  constructor(props) {
    super(props)

    this.state = {
      formData: {
        email: "",
        password: ""
      },
      isRecaptchaChecked: false,
      passwordInvisible: true
    }
  }

  async componentDidMount() {
    await setupSplitFlags.bind(this)({ sub_domain: getSubdomain() })
  }

  allowSubmit = () => {
    const { isSubmitting } = this.props

    return !isSubmitting
  }

  onFieldChange = (field, value) => {
    let updatedFormData = { ...this.state.formData }
    updatedFormData[field] = value
    this.setState({ formData: updatedFormData })
  }

  onKeyDown = (event) => {
    const { onSubmit } = this.props

    if (event.key === EVENTS.ENTER) {
      onSubmit(this.state.formData)
    }
  }

  onClick = () => {
    const { mfa, onSubmit, onSendOTP, t, isFleetCaptchaFlag } = this.props
    const { formData, isRecaptchaChecked } = this.state
    if (isRecaptchaChecked == false && isFleetCaptchaFlag) {
      alert(t("common:recaptchaCheck"))
      return false
    } else {
      if (mfa && sessionHelpers.getApplicationCookie(REMEMBER_ME_COOKIE) === formData.email) {
        onSubmit(formData)
      } else if (mfa) {
        onSendOTP(formData)
      } else {
        onSubmit(formData)
      }
    }
  }

  onEmail = (event) => {
    this.onFieldChange("email", event.target.value)
  }

  onPassword = (event) => {
    this.onFieldChange("password", event.target.value)
  }
  onEyeIconClick = () => {
    this.setState({ passwordInvisible: !this.state.passwordInvisible })
  }

  changedRecaptcha(val) {
    if (val.length > 0) {
      this.props.setState({ isRecaptchaChecked: true })
    } else {
      this.props.setState({ isRecaptchaChecked: false })
    }
  }

  usernameAndPassword = (t) => {
    const { email, password } = this.state.formData
    const { i18n, navigateToForgotPassword, isFleetCaptchaFlag } = this.props
    let recaptchaDocument = document.querySelector('[title="reCAPTCHA"]')
    if (recaptchaDocument) {
      if (!recaptchaDocument.src.match(/(hl=)[^\&]+/)[0].includes(i18n.language))
        recaptchaDocument.src = recaptchaDocument.src.replace(/(hl=)[^\&]+/, `$1${i18n.language}`)
    }
    const fleetAdvise = isFleetAdvise()

    return (
      <div className={"sign-in-form"} onKeyDown={(event) => this.onKeyDown(event)}>
        <div className={"sign-in-email"}>
          {fleetAdvise ? <label>{t("common:emailLabel")}</label> : null}
          <TextField
            className={"sign-in-input"}
            onChange={this.onEmail}
            placeholder={t("common:emailLabel")}
            value={email}
            name="email"
          />
        </div>
        <div className={"sign-in-password"}>
          {fleetAdvise ? <label>{t("common:passwordLabel")}</label> : null}
          <PasswordField
            className={"sign-in-input"}
            onChange={this.onPassword}
            placeholder={t("common:passwordLabel")}
            value={password}
            onIconClick={() => this.onEyeIconClick("password")}
            obfuscated={this.state.passwordInvisible}
          />
        </div>
        {isFleetCaptchaFlag && (
          <ReCAPTCHA
            ref={recaptchaRef}
            className="g-recaptcha"
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            onChange={this.changedRecaptcha}
            hl={i18n.language}
            style={{ padding: "1em" }}
            props={this}
          />
        )}
        <div className={"sign-in-submit"}>
          {
            <button className={"link"} onClick={navigateToForgotPassword}>
              {t("common:forgotPasswordLabel")}
            </button>
          }
          <Button
            className={`button--submit ${isFleetAdvise() ? "signin-btn-color" : ""}`}
            disabled={!this.allowSubmit()}
            label={t("common:signInLabel")}
            onClick={() => this.onClick(t)}
            t={t}
          />
        </div>
      </div>
    )
  }

  mobileStoreButton = (store, link) => {
    return <MobileStoreButton store={store} url={link} linkProps={{ title: "iOS Store Button" }} />
  }

  whiteLabelMobileStoreButton() {
    const iosBaseUrl = "https://itunes.apple.com/us/app/"
    const androidBaseUrl = "https://play.google.com/store/apps/"
    const appPaths = {
      fleetadvise: {
        ios: "fleetamerica/id1361299536?ls=1&mt=8",
        android: "details?id=com.caradvise_fleet_mobile"
      },
      fleetamerica: {
        ios: "fleetamerica/id1361299536?ls=1&mt=8",
        android: "details?id=com.caradvise_fleet_mobile"
      },
      zubie: {
        ios: "zubie-smart-maintenance/id1456945093?ls=1&mt=8",
        android: "details?id=com.caradvise_zubie"
      }
    }

    const subDomain = getSubdomain()
    if (appPaths[subDomain] == null) return null

    const iosButton = () => this.mobileStoreButton("ios", iosBaseUrl + appPaths[subDomain]["ios"])
    const androidButton = () =>
      this.mobileStoreButton("android", androidBaseUrl + appPaths[subDomain]["android"])

    const mobileButton = browserHelpers.browserIsSafari() ? iosButton() : androidButton()

    return <div>{browserHelpers.isMobileBrowser() && mobileButton}</div>
  }

  render() {
    const { t } = this.props
    const isZubieOrFMH = isZubie() || isFleetMaintenanceHub() || isFleetMaintenanceHubCanada()

    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {isZubieOrFMH && (
          <UserOrAdminSignIn usernameAndPassword={this.usernameAndPassword(t)} t={t} />
        )}
        {!isZubieOrFMH && this.usernameAndPassword(t)}
        {this.state.showFooterComponent && this.state.showFooterComponent === OFF ? (
          <PrivacyPolicyAndTerms
            className={isFleetAdvise() ? "privacy-policy-link-color" : ""}
            t={t}
          />
        ) : (
          <div style={{ paddingBottom: "20px", fontSize: "0.8em" }}></div>
        )}
        {this.whiteLabelMobileStoreButton()}
      </div>
    )
  }
} // class SignInForm

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser
})

export default connect(mapStateToProps)(withTranslation("privacyPolicyAndTerms")(SignInForm))

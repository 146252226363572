import { isFleetMaintenanceHubCanada } from "../helpers/affiliationHelpers"
import * as numberHelpers from "../helpers/numberHelpers"
import * as vehicleHelpers from "../helpers/vehicleHelpers"

const CPK = "CPK"
const CPM = "CPM"

export function agGridColumnDefs(
  defaultCellRenderer,
  editCellRenderer,
  deleteCellRenderer,
  deactivateCellRenderer,
  callToActionRenderer,
  t,
  isFleetcor,
  vehicles
) {
  const widths = {
    verySmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    veryLarge: 225
  }
  var fields = []
  if (isFleetcor)
    fields = [
      ...fields,
      {
        headerName: "Enrolled",
        field: "deactivate_button",
        width: widths.medium,
        cellRendererFramework: deactivateCellRenderer,
        suppressMenu: true,
        suppressSorting: true
      }
    ]

  fields = [
    ...fields,
    {
      headerName: t("vehicleLabel"),
      field: "name",
      width: widths.veryLarge,
      cellRendererFramework: callToActionRenderer
    },
    {
      headerName: t("otherIdLabel"),
      field: "other_id",
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("vehicle:vehicleNameLabel"),
      field: "vehicle_name",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("dashboard:DepartmentNumber"),
      field: "department_number",
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("vehicle:vinLabel"),
      field: "vin",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("driversLabel"),
      field: "drivers",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: isFleetMaintenanceHubCanada()
        ? t("dashboard:kilometersLabel")
        : t("dashboard:mileageHeadingLabel"),
      field: isFleetMaintenanceHubCanada() ? KILOMETERS : MILES,
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: isFleetMaintenanceHubCanada() ? CPK : CPM,
      field: "cost_per_mile",
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("lastMaintLabel"),
      field: "last_maint",
      width: widths.large,
      cellRendererFramework: defaultCellRenderer
    }
  ]
  if (vehicles && vehicles.filter((v) => v.fleetcor_enrolled == true).length > 0) {
    fields = [
      ...fields,
      {
        headerName: t("editLabel"),
        field: "edit_button",
        width: widths.verySmall,
        cellRendererFramework: editCellRenderer,
        suppressMenu: true,
        suppressSorting: true
      }
    ]
  }
  if (!isFleetcor)
    fields = [
      ...fields,
      {
        headerName: t("deleteLabel"),
        field: "delete_button",
        width: widths.verySmall,
        cellRendererFramework: deleteCellRenderer,
        suppressMenu: true,
        suppressSorting: true
      },
      {
        headerName: t("vehicle:activatedLbl"),
        field: "deactivate_button",
        width: widths.small,
        cellRendererFramework: deactivateCellRenderer,
        suppressMenu: true,
        suppressSorting: true
      }
    ]
  return fields
}

export function agGridRowData(vehicles, users, language, isFleetcor) {
  return (vehicles || []).map((vehicle) => {
    return {
      name: vehicle.vehicle_name,
      plate: vehicleHelpers.formattedLicensePlateNumber(vehicle, true),
      vin: vehicle.vin,
      other_id: vehicle.other_id,
      vehicle_name: vehicle.name_of_vehicle,
      department_number: vehicle.department_number,
      license_plate_number: vehicle.license_plate_number,
      drivers: vehicleHelpers.formattedDriverNames(vehicle.driver_names, language),
      [isFleetMaintenanceHubCanada() ? KILOMETERS : MILES]: numberHelpers.formatWithCommas(
        isFleetMaintenanceHubCanada() ? vehicle.kilometers : vehicle.miles
      ),
      last_maint: vehicleHelpers.formattedLastMaintenanceDate(vehicle, language),
      cost_per_mile: vehicleHelpers.formattedCostPerMile(vehicle),
      edit_button: vehicle,
      delete_button: vehicle,
      deactivate_button: vehicle,

      // NOTE: The ID column does not get displayed because there is no mapping for it in the columnDefs, however, providing here makes it available to the row's data attribute.
      id: vehicle.id,
      vehicle: vehicle,
      fleet_id: vehicle.fleet_id,
      fleet_name: vehicle.fleet_name
    }
  })
}

export function agGridFMCColumnDefs(defaultCellRenderer, t, vehicles, fmc) {
  const widths = {
    verySmall: 125,
    small: 150,
    medium: 175,
    large: 200,
    veryLarge: 225
  }
  var fields = []
  fields = [
    ...fields,
    {
      headerName: t("vehicleLabel"),
      field: "name",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("otherIdLabel"),
      field: "other_id",
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("vehicle:vehicleNameLabel"),
      field: "vehicle_name",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("dashboard:DepartmentNumber"),
      field: "department_number",
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("vehicle:vinLabel"),
      field: "vin",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("vehicle:licencePlateNumber"),
      field: "license_plate_number",
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("vehicle:fleetLabel"),
      field: "fleet_name",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: t("driversLabel"),
      field: "drivers",
      width: widths.veryLarge,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: isFleetMaintenanceHubCanada()
        ? t("dashboard:kilometersLabel")
        : t("dashboard:mileageHeadingLabel"),
      field: isFleetMaintenanceHubCanada() ? KILOMETERS : MILES,
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    },
    {
      headerName: isFleetMaintenanceHubCanada() ? CPK : CPM,
      field: "cost_per_mile",
      width: widths.medium,
      cellRendererFramework: defaultCellRenderer
    }
  ]
  return fields
}

export const KILOMETERS = "kilometers"
export const MILES = "miles"
export const NA = "N/A"
export const FLEET = "Fleet"
export const CUSTOMER = "Customer"

export function handleKeypress(e) {
  var regex = new RegExp("^[a-zA-Z0-9]+$")
  var key = String.fromCharCode(!e.charCode ? e.which : e.charCode)
  if (!regex.test(key)) {
    e.preventDefault()
    return false
  }
}
